import * as React from 'react';
import { toggleSimCard, toggleSubscription, toggleUsage } from '../../features/customer/customerToggleSlice';
import { useSelector, useDispatch } from 'react-redux';
import { KeyWord } from '../../constants';



export const GetCustomerTabBar = () => {
	
	const dispatch = useDispatch();

	const { component } = useSelector((state) => state.customerToggle);
	
	return (
		<>
			<div className='checkout-content-box__customer margin-t-xl'>
				<div className='tabs'>
					<button 
						className={`tab-item bullet-list ${component === 'subscription' ? 'active' : ''}`}
						onClick={() => {dispatch(toggleSubscription())}}>
							{KeyWord.subscription}
					</button>

					<button 
						className={`tab-item sim ${component === 'sim' ? 'active' : ''}`}
						onClick={() => {dispatch(toggleSimCard())}}>
							{KeyWord.sim_card}
					</button>
					
					<button 
						className={`tab-item usage ${component === 'usage' ? 'active' : ''}`}
						onClick={() => {dispatch(toggleUsage())}}>
							{KeyWord.usage}
					</button>
				</div>
			</div>
		</>
	);
};

export default GetCustomerTabBar;