import * as React from 'react';
import { CustomerSubscriptionItem } from './customer-subscription-item.component'
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import TextInput from '../form-components/text-input.component';
import { FormName, YupError } from '../../constants';
import { useSelector, useDispatch } from "react-redux";
import { KeyWord } from '../../constants';
import LoaderSmall from '../loader/loader-small.component';
import { getCustomerRequest, setError } from '../../features/customer/customerSlice';
import {clearSubscriptions, setActive, toggleInvoiceView} from '../../features/customer/customerSubscriptionsSlice';



export const CustomerSidebar = () => {
	const { isLoading } = useSelector((store)=>store.customer)
	const { customerSubscriptions, active, accountOwner, error, isSubscriptionsLoading } = useSelector((store)=>store.customerSubscriptionList)
	const dispatch = useDispatch();

	const customerDataSubmitHandler = (customer_phone_number) => {
		return dispatch(getCustomerRequest({phone_number:customer_phone_number}));
	}

	const clearSubscriptionsHandler = () => {
		dispatch(clearSubscriptions());
	}

	return (
		<>
			<div className='sidebar'>
				<div>
					<Formik
						initialValues={{
							customer_phone_number: ''
						}}
						validationSchema={Yup.object().shape({
							customer_phone_number: Yup.string()
								.matches(/^[0-9]+$/,YupError.invalid_characters)
								.min(8, YupError.too_short)
								.max(8, YupError.too_long)
								.required(YupError.required),
						})}
						autocomplete='off'
						onSubmit={
							(values)=>{
								clearSubscriptionsHandler();
								customerDataSubmitHandler(values.customer_phone_number)
								.catch((error) => {
									setError({
										code: 500,
										description: `Technical error: ${error.message}.`,
										translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
									})
								})
							}
						}
					>
						{({ values, status, errors, touched, isValid, submitCount, setFieldValue, handleSubmit, ...props }) => (
							<Form>
								<div className='sidebar--search margin-b-m'>
								<TextInput
									label={FormName.customer_phone_number.label}
									label_small={FormName.customer_phone_number.label_small}
									name={FormName.customer_phone_number.name}
									type='search'
									tool_tip={FormName.customer_phone_number.tool_tip}
								/>
									<button
										disabled={isLoading}
										type='submit'
									>
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
				{
					isSubscriptionsLoading &&
					<div className='center-content'>
						<LoaderSmall isEnabled={isSubscriptionsLoading} extraClass='margin-l-s'/>
					</div>
				}
				{
					error &&
					<div className='center-content'>
						<div className='base-alert --error margin-t-l'>
							{error.translation ? error.translation : null}
						</div>
					</div>
				}
				{
					accountOwner && !error && !isSubscriptionsLoading &&
					<div className='subscription-list margin-t-xl'>
						<div className={ 'account_owner' === active ? 'subscription active margin-t-s':'subscription margin-t-s'}
							 onClick = {() => {
								 dispatch(setActive('account_owner'));
								 dispatch(toggleInvoiceView());
							 }}
						>
							<div className="account-owner-card">
								<p>ID: {accountOwner.id}</p>
								<h5>{ accountOwner.full_name }</h5>
							</div>
							
						</div>
					</div>
				}
				{
					customerSubscriptions &&
					<div className='data-columns margin-t-l'>
						<div className='icon phone --small' />
						<p className='title'>{KeyWord.subscriptions}:</p>
					</div>
				}
						<div className='subscription-list'>
								{
									customerSubscriptions &&
									customerSubscriptions.map((item) => {
										return <CustomerSubscriptionItem key={item.reference_id} {...item}/>
									})
								}
						</div>
			</div>
		</>
	);
};

export default CustomerSidebar;