import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Action, Auth } from '../../constants';
import { Buffer } from 'buffer';

export const getSubscriptionsList = createAsyncThunk('customer/getSubscriptions', async (data, thunkAPI) => {
	let myHeaders = new Headers();
	let loggedUserData = localStorage.getItem("user");
	let loggedUser = JSON.parse(loggedUserData);
	const auth_token = loggedUser.auth_token;
	const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
	myHeaders.append('Authorization', 'Bearer ' + auth_token)
	myHeaders.append('Api-Token', encodedApiToken)
	let requestOptions = {
		method: 'GET',
		headers: myHeaders
	};

	const result = await fetch(Action.GET_SUBSCRIPTION_LIST_URL+data.reference_id,requestOptions)
	.then((resp) => resp.json());

	const currentCustomerReferenceId = await thunkAPI.getState().customer.customerData.reference_id;
	thunkAPI.dispatch(setBuffer(currentCustomerReferenceId));

	return result;
})

const initialState = {
	customerSubscriptions: null,
	originalResponse: {},
	accountOwner: null,
	showInvoices: false,
	buffer: null,
	active: null,
	error: null,
	isSubscriptionsLoading: false,
};

const customerSubscriptionsSlice = createSlice({
	name: 'customerSubscriptions',
	initialState,
	reducers: {
		setBuffer : (state, action) => {
			state.buffer = action.payload;
		},
		setActive : (state, action) => {
			state.active = action.payload;
			state.showInvoices = false;
		},
		toggleInvoiceView: (state) => {
			if (state.showInvoices){
				state.showInvoices = false;
			} else {
				state.showInvoices = true;
			}
		},
		setSubscriptionsError:(state, action) => {
			state.error = action.payload;
		},
		clearSubscriptions : (state) => {
			state.customerSubscriptions = null;
			state.originalResponse = {};
			state.accountOwner = null;
			state.active = null;
			state.buffer = null;
			state.error = null;
			state.isSubscriptionsLoading = false;
		},
		setAccountOwner:(state, action) => {
			state.accountOwner = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
		.addCase(getSubscriptionsList.pending, (state) => {
			state.customerSubscriptions = null
			state.originalResponse = {}
			state.active = null
			state.buffer = null
			state.error = null
			state.isSubscriptionsLoading = true
			state.showInvoices = false
		})
		.addCase(getSubscriptionsList.fulfilled, (state, action) => {
			state.isSubscriptionsLoading = false
			state.originalResponse = action.payload
			if (action.payload?.error?.code) {
				state.error = action.payload.error
			} else if (action.payload?.message?.success && action.payload?.data ) {
				state.customerSubscriptions = action.payload.data
				state.active = state.customerSubscriptions.find((item) => item.reference_id === state.buffer).reference_id
			}
		})
		.addCase(getSubscriptionsList.rejected, (state, action) => {
			state.customerSubscriptions = null
			state.originalResponse = {}
			state.isSubscriptionsLoading = false
			state.error = {
				code: 500,
				description: `Technical error: ${action.error.message}`,
				translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${action.error.message}`,
			}
		})
	},

});

export const { setActive, setSubscriptionsError, clearSubscriptions, setAccountOwner, setBuffer, toggleInvoiceView } = customerSubscriptionsSlice.actions;

export default customerSubscriptionsSlice.reducer;
