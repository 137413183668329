import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import { productOptions } from "../../customerData";
import { Action, Auth } from "../../constants";
import { Buffer } from 'buffer';


export const getProductOptionsRequest = createAsyncThunk('customer/getProductOptions', async (reference_id) => {
	let myHeaders = new Headers();
	let loggedUserData = localStorage.getItem("user");
	let loggedUser = JSON.parse(loggedUserData);
	const auth_token = loggedUser.auth_token;
	const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
	myHeaders.append('Authorization', 'Bearer ' + auth_token)
	myHeaders.append('Api-Token', encodedApiToken)
	let requestOptions = {
		method: 'GET',
		headers: myHeaders
	};
	return await fetch(Action.GET_CUSTOMER_PRODUCT_OPTIONS_ENDPOINT_URL+reference_id,requestOptions)
	.then((response) => response.json());
})


const initialState = {
	productOptionList: null,
	originalResponse: {},
	error: null,
	isLoading: false,
};

const customerProductOptionSlice = createSlice({
	name: 'customerProductOptionList',
	initialState,
	reducers: {
		setProductError: (state, action) => {
			state.error = action.payload
		},
		toggleProductOption: (state, {payload})=>{
			const optionItem = state.productOptionList.find((item) => item.name === payload);
			optionItem.value = optionItem.value? false : true;
			optionItem.pending = optionItem.product_option_type === "calling_package_abroad" ? false : true;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getProductOptionsRequest.pending, (state) => {
			state.productOptionList = null
			state.originalResponse = {}
			state.isLoading = true
			state.error = null
		})
		.addCase(getProductOptionsRequest.fulfilled, (state, action) => {
			state.isLoading = false
			state.originalResponse = action.payload
			if (action.payload?.error?.code) {
				state.error = action.payload.error
			} else if (action.payload?.message?.success && action.payload?.data ) {
				state.productOptionList = action.payload.data
			}
		})
		.addCase(getProductOptionsRequest.rejected, (state, action) => {
			state.productOptionList = null
			state.originalResponse = {}
			state.isLoading = false
			state.error = {
				code: 500,
				description: `Technical error: ${action.error.message}`,
				translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${action.error.message}`,
			}
		})
	}
});

export const { toggleProductOption, setProductError } = customerProductOptionSlice.actions;

export default customerProductOptionSlice.reducer;