const EnvConfig = { ...process.env, ...window['env'] };
const Environment = EnvConfig['NODE_ENV'];

const BaseUrl = Environment === 'production' ?
	process.env.REACT_APP_API_URL :
	Environment === 'staging' ?
		process.env.REACT_APP_STAGING_API_URL :
		process.env.REACT_APP_DEV_API_URL;

// const BaseUrl = process.env.REACT_APP_API_URL
		
const Action = {
	POST_LOGIN_ENDPOINT_URL: `${BaseUrl}/user-api/v1/login`,
	POST_LOGOUT_ENDPOINT_URL: `${BaseUrl}/user-api/v1/logout`,
	GET_CUSTOMER_DATA_ENDPOINT_URL: `${BaseUrl}/customer-api/v1/customer/base-data`,
	GET_CUSTOMER_PRODUCT_OPTIONS_ENDPOINT_URL: `${BaseUrl}/customer-api/v1/customer/product-options?reference_id=`,
	GET_CUSTOMER_BARRING_OPTIONS_ENDPOINT_URL: `${BaseUrl}/customer-api/v1/customer/barring-options?reference_id=`,
	GET_CUSTOMER_SIMS_ENDPOINT_URL: `${BaseUrl}/customer-api/v1/customer/sim-card-list?reference_id=`,
	REPLACE_SIM_CARD_ENDPOINT_URL: `${BaseUrl}/customer-api/v1/customer/replace-sim-card?reference_id=`,
	GET_SUBSCRIPTION_LIST_URL: `${BaseUrl}/customer-api/v1/customer/subscription-list?reference_id=`,
	POST_CUSTOMER_TOGGLE_OPTIONS_ENDPOINT_URL: `${BaseUrl}/customer-api/v1/customer/toggle-option?reference_id=`,
	GET_POSSIBLE_PRODUCT_LIST_URL: `${BaseUrl}/customer-api/v1/customer/price-plan-list?reference_id=`,
	POST_CHANGE_PRICE_PLAN_URL: `${BaseUrl}/customer-api/v1/customer/change-price-plan?reference_id=`,
	GET_PRICE_PLAN_URL: `${BaseUrl}/customer-api/v1/customer/price-plan?reference_id=`,
	GET_INVOICE_LIST_ENDPOINT_URL: `${BaseUrl}/customer-api/v1/customer/invoice-list?reference_id=`,
	PUT_CANCEL_PRICE_PLAN_CHANGE_URL: `${BaseUrl}/customer-api/v1/customer/cancel-price-plan-change?reference_id=`,
	GET_INVOICE_DOWNLOAD_ENDPOINT_URL: `${BaseUrl}/customer-api/v1/customer/invoice-download-link`,
	POST_RESEND_INVOICE_ENDPOINT_URL: `${BaseUrl}/customer-api/v1/customer/resend-invoice`,
	GET_CUSTOMER_USAGE_ENDPOINT_URL: `${BaseUrl}/customer-api/v1/customer/usage`,
	GIGABYTE_VALUE: 1000
};

const Variables = {
	new_number_price: 59
}

const YupError = {
	required: 'Feltet er obligatorisk',
	invalid_characters: 'Ugyldige tegn',
	invalid_date: 'Dato er i feil format. Må være dd.mm.åååå',
	invalid_email: 'E-post adressen er feil',
	too_short: 'Antall tegn er for lavt',
	too_long: 'Antall tegn er for høyt',
	must_have: 'Vennligst velg minimum ett abonnement',
	terms_and_conditions: 'Vennligs aksepter våre vilkår og betingelser',
	missing_subscription: 'Abonnementet mangler',
	check_your_rights: 'Du må krysse av for at du har den rette fullmakten',
	sales_data_is_missing: 'Selger mangler',
};

const FormName = {
	full_name: {
		name: 'full_name',
		label: 'Fullt navn*',
		label_small: '(Fornavn Mellomnavn Etternavn)',
		placeholder: 'Fornavn Mellomnavn Etternavn',
		tool_tip: 'Ditt fulle navn slik det står oppgitt i folkeregisteret.',
	},
	contact_person_full_name: {
		name: 'contact_person_full_name',
		label: 'Fullt navn*',
		label_small: '(Fornavn Mellomnavn Etternavn)',
		placeholder: 'Fornavn Mellomnavn Etternavn',
		tool_tip: 'Ditt fulle navn slik det står oppgitt i folkeregisteret.',
	},
	invoice_email: {
		name: 'invoice_email',
		label: 'Faktura sendes på e-post til',
		label_small: '',
		placeholder: 'E-post',
		tool_tip: 'Oppgi e-post adressen vi kan sende faktura til',
	},
	organisation_number: {
		name: 'organisation_number',
		label: 'Organisasjonsnummer',
		label_small: '(9 siffer / kun norske organisasjoner)',
		placeholder: 'Organisasjonsnummer',
		tool_tip: 'Vi vil automatisk hente info om bedriften basert på organisasjonsnummeret',
	},
	personal_number: {
		name: 'personal_number',
		label: 'Fødselsnummer*',
		label_small: '(11 siffer)',
		placeholder: 'Personnummer',
		tool_tip: 'Vi trenger å vite ditt fødselsnummer for å gjøre en kredittsjekk. Fødselsnummeret ditt vil ikke bli lagret hos oss.',
	},
	email: {
		name: 'email',
		label: 'E-post*',
		label_small: '(fakturaadresse)',
		placeholder: 'E-post',
		tool_tip: 'E-post adressen vi kan kontakte deg på, og sende faktura til.',
	},
	contact_person_email: {
		name: 'contact_person_email',
		label: 'E-post*',
		label_small: '(for kontakt)',
		placeholder: 'E-post',
		tool_tip: 'E-post adressen vi kan kontakte deg på, og sende faktura til.',
	},
	contact_phone_number: {
		name: 'contact_phone_number',
		label: 'Telefonnummer* ',
		label_small: '(for kontakt)',
		placeholder: 'Telefonnummer',
		tool_tip: 'Telefonnummeret vi kan kontakte deg på.',
	},
	birthday: {
		name: 'birthday',
		label: 'Fødselsdato*',
		label_small: '(dd.mm.åååå)',
		placeholder: 'dd.mm.åååå',
		tool_tip: 'Vi trenger fødselsdato for å kjenne alder på brukerne og identifisere mindreårige.',
	},
	userFullName: {
		name: 'userFullName',
		label: 'LABEL - userFullName',
		label_small: 'LABEL_SMALL - userFullName',
		placeholder: 'PLACEHOLDER - userFullName',
		tool_tip: 'TOOLTIP - userFullName',
	},
	number: {
		name: 'number',
		label: 'Mobilnummer på abonnementet*',
		label_small: '',
		placeholder: 'Mobilnummer',
		tool_tip:
			'Telefonnummeret som skal tilknyttes abonnementet. Merk at du som bestiller må være eier av dette telefonnummeret for at bestillingen skal gå igjennom.',
	},
	order_new_number: {
		name: 'order_new_number',
		label: 'Nytt nummer (etablering kr.' + Variables.new_number_price + ',-)',
		label_small: 'LABEL_SMALL - isNew',
		placeholder: 'PLACEHOLDER - isNew',
		tool_tip: 'TOOLTIP - isNew',
	},
	hiddenForOtherServices: {
		name: 'hiddenForOtherServices',
		label: 'Nummeret skal ikke vises på opplysningstjenester som 1881 o.l',
		label_small: 'LABEL_SMALL - hiddenForOtherServices',
		placeholder: 'PLACEHOLDER - hiddenForOtherServices',
		tool_tip: 'TOOLTIP - hiddenForOtherServices',
	},
	is_owner: {
		name: 'is_owner',
		label: 'Eier og bruker er samme person',
		label_small: 'LABEL_SMALL - isOwnerSameAsUser',
		placeholder: 'PLACEHOLDER - isOwnerSameAsUser',
		tool_tip: 'TOOLTIP - isOwnerSameAsUser',
	},
	terms: {
		name: 'terms',
		label: 'Jeg godtar Release sine vilkår og personvernerklæring',
		label_small: 'LABEL_SMALL - acceptTerms',
		placeholder: 'PLACEHOLDER - acceptTerms',
		tool_tip: 'TOOLTIP - acceptTerms',
	},
	copyData: {
		name: 'copyData',
		label: 'Eier og bruker er samme person',
		label_small: 'LABEL_SMALL - copyData',
		placeholder: 'PLACEHOLDER - copyData',
		tool_tip: 'TOOLTIP - copyData',
	},
	newsletter: {
		name: 'newsletter',
		label: 'Jeg godkjenner at Release kan sende meg relevante tilbud og informasjon i digitale kanaler (for eksempel e-post og SMS).',
		label_small: 'LABEL_SMALL - sendNewsLetters',
		placeholder: 'PLACEHOLDER - sendNewsLetters',
		tool_tip: 'TOOLTIP - sendNewsLetters',
	},
	campaign_code: {
		name: 'campaign_code',
		label: 'Kampanjekode',
		label_small: '(dersom du har mottatt en)',
		placeholder: 'Kampanjekode',
		tool_tip: 'Koden valideres etter gjennomført bestilling',
	},
	portation_day: {
		name: 'portation_day',
		label: 'Porteringsdato',
		label_small: '(dato er begrenset til hverdager, maks 1 mnd frem i tid)',
		placeholder: '',
		tool_tip: '',
	},
	sim_card_icc: {
		name: 'sim_card_icc',
		label: 'SIM-kort ICCID',
		label_small: '',
		placeholder: '',
		tool_tip: '',
	},
	show_device_imei: {
		name: 'show_device_imei',
		label: 'Telefon skal ettersendes',
		label_small: '',
		placeholder: '',
		tool_tip: '',
	},
	show_sim_card_icc: {
		name: 'show_sim_card_icc',
		label: 'SIM-kort skal ettersendes',
		label_small: '',
		placeholder: '',
		tool_tip: '',
	},
	need_to_provide: {
		name: 'need_to_provide',
		label: 'SIM-kort skal ettersendes',
		label_small: '',
		placeholder: '',
		tool_tip: '',
	},
	leasing_period_months: {
		name: 'leasing_period_months',
		label: 'Leasing periode',
		label_small: '',
		placeholder: '',
		tool_tip: '',
	},
	device_imei: {
		name: 'device_imei',
		label: 'Telefon IMEI',
		label_small: '',
		placeholder: '',
		tool_tip: '',
	},
	identity_document_type: {
		name: 'identity_document_type',
		label: 'Identifikasjons type',
		label_small: '(Pass, Bankkort, førerkort osv.)',
		placeholder: '',
		tool_tip: '',
	},
	identity_document_id: {
		name: 'identity_document_id',
		label: 'Identifikasjons nummer',
		label_small: '',
		placeholder: '',
		tool_tip: '',
	},
	subscription_bound_months: {
		name: 'subscription_bound_months',
		label: 'Bindingstid abonnement',
		label_small: '',
		placeholder: '',
		tool_tip: '',
	},
	extra_data_sim_card_list: {
		name: 'extra_data_sim_card_list',
		label: 'ICCID - Data SIM',
		label_small: '',
		label_secondary: 'Data SIM',
		placeholder: '',
		tool_tip: '',
	},
	twin_sim_card_list: {
		name: 'twin_sim_card_list',
		label: 'ICCID - Tvilling SIM',
		label_small: '',
		label_secondary: 'Tvilling SIM',
		placeholder: '',
		tool_tip: '',
	},
	product_id: {
		name: 'product_id',
		label: 'Abonnement',
		label_small: '',
		placeholder: 'Velg abonnement',
		tool_tip: '',
	},
	salesperson: {
		name: 'salesperson',
		label: 'Selger',
		label_small: '',
		placeholder: 'Velg selger',
		tool_tip: '',
	},
	paper_invoice: {
		name: 'paper_invoice',
		label: 'Kryss av for papir faktura',
		label_small: 'LABEL_SMALL - sendPaperMail',
		placeholder: 'PLACEHOLDER - sendPaperMail',
		tool_tip: 'TOOLTIP - sendPaperMail',
	},
	contact_postal_address: {
		name: 'contact_postal_address',
		label: 'Postadresse',
		label_small: '',
		placeholder: '',
		tool_tip: '',
	},
	contact_postal_city: {
		name: 'contact_postal_city',
		label: 'Postby',
		label_small: '',
		placeholder: 'Oslo',
		tool_tip: '',
	},
	contact_postal_code: {
		name: 'contact_postal_code',
		label: 'Postnummer',
		label_small: '',
		placeholder: '123456',
		tool_tip: '',
	},
	seller_name: {
		name: 'seller_name',
		label: 'Selgers navn',
		label_small: '',
		placeholder: 'John',
		tool_tip: '',
	},
	vipps_phone_number: {
		name: 'vipps_phone_number',
		label: 'Telefonnummer* ',
		label_small: '',
		placeholder: 'Telefonnummer',
		tool_tip: '',
	},
	customer_phone_number: {
		name: 'customer_phone_number',
		label: 'Telefonnummer* ',
		label_small: '',
		placeholder: 'Telefonnummer',
		tool_tip: 'Telefonnummer',
	},
	current_sim_card_icc: {
		name: 'current_sim_card_icc',
		label: 'Nåværende SIM-kort ICCID',
		label_small: '',
		placeholder: '',
		tool_tip: '',
	},
	new_sim_card_icc: {
		name: 'new_sim_card_icc',
		label: 'Nytt SIM-kort ICCID',
		label_small: '',
		placeholder: '',
		tool_tip: '',
	},
	activation_email: {
		name: 'activation_email',
		label: 'E-post*',
		label_small: '(for aktivering)',
		placeholder: 'E-post',
		tool_tip: 'E-post adressen vi kan kontakte deg på, og sende faktura til.',
	},
	change_product: {
		name: 'new_product',
		label: 'Nytt produkt',
		label_small: '',
		placeholder: 'Velg produkt',
		tool_tip: '',
	},
	login_username: {
		name: 'login_username',
		label: 'Brukernavn',
		label_small: '',
		placeholder: 'Brukernavn',
		tool_tip: '',
	},
	login_password: {
		name: 'login_password',
		label: 'Passord',
		label_small: '',
		placeholder: 'Passord',
		tool_tip: '',
	},
	phone_number: {
		name: 'phone_number',
		label: 'Telefonnummer',
		label_small: '',
		placeholder: 'Telefonnummer',
		tool_tip: 'Phone number where we send instructions',
	}
};

const ContractSigningInstructionsSendingFormName = {
	email: {
		name: 'email',
		label: 'E-post',
		label_small: '',
		placeholder: 'E-post',
		tool_tip: 'Email address where we send instructions',
	},
	phone_number: {
		name: 'phone_number',
		label: 'Telefonnummer',
		label_small: '',
		placeholder: 'Telefonnummer',
		tool_tip: 'Phone number where we send instructions',
	},
	sms_sending_method: {
		name: 'sending_method',
		id: 'sms_sending_method',
		label: 'SMS',
		value: 'sms',
	},
	email_sending_method: {
		name: 'sending_method',
		id: 'email_sending_method',
		label: 'Epost',
		value: 'email',
	}
};

const Auth = {
	user: process.env.REACT_APP_API_AUTH_USER,
	password: process.env.REACT_APP_API_AUTH_PASSWORD,
};

const DistributionCode = {
	paper: 1,
	email: 2,
};

const KeyWord = {
	show_only_cost: 'Show only items with cost',
	filter_usage: 'Filter usage by type',
	empty_usage: 'No records this month',
	usage: 'Usage',
	fetch_vipps_data_section_title: 'Hent kundeinformasjon via Vipps',
	fetch_vipps_data_buton_label: 'Vipps',
	credit_check_section_title: 'Kredittsjekk',
	personal_data_section_title: 'Bestiller / Juridisk Eier',
	number_section_title: 'Abonnement',
	add_order: 'Ny Kunde',
	kr_small: 'kr',
	sponsor_fee_description_part_1: 'Hvorav ',
	sponsor_fee_description_part_2: ' går til klubben',
	mobile: 'Mobil:',
	about_company: 'Om bedriften',
	contact_person: 'Kontaktperson',
	shop_and_order: 'Handlekurv og bestilling',
	who_are_you: 'Hvem er du?',
	e_data: 'Mobilt Bredbånd',
	remove: 'Slett',
	data: 'Data',
	twin: 'Tvilling',
	send_billing_info: 'Fortell oss hvem som skal eie abonnementet og betale fakturaen',
	order: 'Abonnement',
	order_plus: '+Abonnement',
	delete: 'Slett',
	delete_subscription: 'Slett abonnement',
	change: 'Endre',
	add_more_orders: 'Legg til flere abonnement',
	confirm_and_order: 'Godkjenn og bestill',
	general_info: 'Generell informasjon',
	list_item_1: 'Når du klikker «Bestill» gjør vi en kredittsjekk. Du får umiddelbart svar på om du kan bli Release-kunde.',
	list_item_2:
		'Ved godkjent kredittsjekk, mottar du en SMS og e-post med link til bankID-signering. Dette for å for å identifisere deg og for å bekrefte bestillingen.',
	list_item_3: 'SIM-kort og eventuell telefon(er), sendes normalt i løpet av 3 dager.',
	list_item_4: 'Ved bestilling av leietelefon og SIM:',
	list_item_4_1: 'Du får 12 måneders avtaleperiode. ',
	list_item_4_2: 'Sier du opp abonnementet i avtaleperioden, vil sluttfakturaen inneholde de siste månedsavgiftene.',
	list_item_4_3: 'Ved oppsigelse skal telefonen(e) du leier returneres til Release.',
	list_item_4_4: 'Vi garanterer at telefonbatteri og teknologi fungerer tipp topp i hele leieperioden',
	bs_list_item_1: 'Når du klikker «Bestill» gjør vi en kredittsjekk. Du får umiddelbart svar på om din bedrift kan bli Release-kunde.',
	bs_list_item_2:
		'Ved godkjent kredittsjekk, mottar du en SMS og e-post med link til bankID-signering. Dette for å for å identifisere deg og for å bekrefte bestillingen på vegne av bedriften.',
	bs_list_item_3: 'SIM-kort og telefon(er), sendes normalt i løpet av 3 dager.',
	bs_list_item_4: 'Ved bestilling av leietelefon og SIM:',
	bs_list_item_4_1: 'Dere får 24 måneders avtaleperiode',
	bs_list_item_4_2: 'Sier dere opp abonnementet i avtaleperioden, påløper ekstra kostnader i tråd med vilkårene.',
	bs_list_item_4_3: 'Ved oppsigelse skal telefonen(e) som leies returneres til Release.',
	bs_list_item_4_4: 'Vi garanterer at telefonbatteri og teknologi fungerer tipp topp i hele leieperioden',
	bs_list_item_5: 'Alle priser er oppgitt ekskl. mva.',
	confirm: 'Godkjenning',
	total_price_kr: 'Total pris kr. ',
	coma_dash: ',- ',
	per_month: ' pr.mnd / Kr. ',
	per_month_no_kr: ' pr.mnd ',
	kr: 'Kr. ',
	get_new_nr: ' i etablering',
	confirm_order: 'Bestill',
	firm: 'Firma',
	invoice_sent_to: 'Faktura sendes til',
	send_sim: 'Brev og pakker sendes til:',
	new_number: 'Nytt nummer',
	users: 'Brukere / Abonnementer',
	name: 'Navn',
	phone_nr: 'Mobilnummer',
	subscription: 'Abonnement',
	subscription_id: 'Abonnement',
	phone: 'Telefon',
	join_price: 'Etablering',
	monthly_price: 'Månedspris',
	invoice_email_description:
		'I Release tenker vi at ett papirark mindre, er ett av flere bidrag til kloden. Trenger du faktura i posten, kan du endre dette på Min Bedrift etter bestilling.',
	company_description: 'Brev og pakker sendes til:',
	get_orig_data: 'Hent',
	choose_your_item: 'Velg og legg til din Release-pakke',
	with_phone: 'Med Leietelefon',
	without_phone: 'Uten Leietelefon',
	close: 'Lukk',
	benefit_1: 'Ingen bindingstid',
	benefit_2: 'Fri tale og SMS',
	benefit_3: 'Telenor-dekning',
	benefit_4: 'Fri roaming i EU',
	gb: 'GB',
	ready_to_use: 'Nyoppusset og klar til bruk!',
	select: 'Velg',
	amount_of_data_per_month: 'datamengde pr.mnd:',
	monthly_price_desc: 'Pakkepris pr. måned',
	hardware_article_title: 'Lorem ipsum',
	monthly_price_extra: 'Minstepris',
	months_add: ' måneder: ',
	hardware_article_desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
	more_info: 'Mer informasjon',
	benefit_product_1: 'Ingen bindingstid',
	benefit_product_2: 'Fri tale og SMS',
	benefit_product_3: 'Telenor-dekning',
	benefit_product_4: 'Fri roaming i EU',
	product_article_title: 'Lorem ipsum',
	product_article_desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
	month_price: 'Månedspris',
	popis: 'Poppis',
	loading: 'Din bestilling er under behandling. Vennligst vent.',
	who_is_ordering: 'Hvem skal bruke abonnementet?',
	select_color: 'Velg farge',
	in_stock: ' stk på lager',
	product_extras: 'Fri tale, SMS og MMS i Norge og EU.',
	agreement_period: 'Avtaleperiode abonnement:',
	months: ' måneder',
	max_out: 'Du har nådd 6 stk. abonnement som er maksimum tillatt bestilling',
	campaign_code_title: 'Kampanjekode',
	normal_price: 'Normal pris',
	hardware_leasing_period: 'Avtaleperiode leiemobil:',
	error_heading: 'Påkrevde felter er tomme eller ikke utfylt på korrekt måte',
	router_heading: 'Bredbåndsrouter',
	contract_is_signed: 'Kontrakten er signert',
	start_digital_signing: 'Start digital signering',
	start_manual_signing: 'Manuell signering utført',
	start_contract_confirmation: 'Send avtale',
	start_contract_confirmation_resend: 'Send avtalen på nytt',
	signant_loading_description: 'Dersom du har valgt en telefon vil du nå bli sendt videre til avtalesignering med BankID',
	error_code: 'En feil oppstod - ',
	error_description: 'Feilmelding:',
	order_header: 'Hvem skal bruke abonnementet?',
	zero_months: '0 måneder',
	twelve_months: '12 måneder',
	one_time_fee: ' engangskost',
	choose_signing_mode: 'Velg signeringsmetode',
	bind_router: ' (ingen binding)',
	contract_period: 'mnd avtaletid på abonnementet)',
	router_order_header: 'Trenger du router til ditt abonnement?',
	buy_router: 'Kjøp en router til rabattert pris',
	choose_router_from_here: 'Velg en bredbåndsrouter',
	price_and_discount: 'Pris og rabatt',
	yes_please: 'Ja takk, gi meg ',
	get_discount: ',- i rabatt mot ',
	months_contract: ' mnd avtaletid på abonnementet',
	business_newsletter_label: 'Jeg bekrefter at jeg har fullmakt til å bestille på vegne av bedriften',
	business_order_new_number_label: 'Nytt nummer (etablering kr.199,-)',
	business_is_owner_label: 'Denne brukeren skal være administrator på Min Bedrift',
	initial_loading: 'Laster...',
	contract_creator: 'Selger',
	make_credit_check: 'Utfør sjekk',
	customer_is_creditworthy: 'Kredittsjekk godkjent',
	customer_is_not_creditworthy: 'Det er oppdaget en kredittsperre. Kunden må gå inn på https://soliditet.no/privatpersoner/auth og oppheve sperren.',
	how_to_provide_contract_signing_instructions: 'Velg hvor kunden skal motta bestillingen for å bekrefte avtalen',
	start_contract_confirmation_success: 'Fullført',
	start_contract_confirmation_success_message: 'Avtalen er sent til kunden for signering',
	order_overview: 'Bestilling',
	contact_physical_signing_completed_success: 'Fullført',
	contact_physical_signing_completed: 'Ordren er fullført',
	back: 'Tilbake',
	new_signup_order: 'Ny Bestilling',
	contract_signing_status: 'Status',
	reset_form: 'Tøm skjema',
	add_new: 'Legg til ny',
	manage_sales_person: 'Legg til selger',
	price_plan_supposed_binding_period_string_start: 'Forutsetter',
	price_plan_supposed_binding_period_string_end: 'mnd binding',
	after: 'etter',
	months_short: 'mnd',
	customer_data: 'Kunde data',
	get_data: 'få data',
	order_history: 'Ordrehistorikk',
	number_status_active: 'Aktiv',
	pending: 'Planlagt',
	number_status_failed: 'Feilet',
	cancelled: 'Avbrutt',
	unknown: 'Ukjent',
	skip_vipps_button_label: 'Fyll inn fødsels og personnummer manuelt',
	product: 'Produkt',
	sim_card: 'SIM-kort',
	start_date: 'Startdato',
	end_date: 'Slutdato',
	edit: 'Redigere',
	qr_code: 'QR kode',
	active: 'Aktiv',
	new_sim_card_reserved: 'Nytt sim-kort reservert',
	calling_package_abroad: 'Ringepakke til utlandet',
	twin_sim: 'Tvilling SIM',
	voice_mail: 'Talepost',
	data_sim: 'Data SIM',
	product_options: 'Produktalternativer',
	barring_options: 'Sperringsalternativer',
	international_out: 'Internasjonalt ut',
	premium: 'Premie',
	outgoing: 'Utgående',
	locked: 'Låst',
	sim_card_service_type_regular: 'Hoved-SIM',
	sim_card_service_type_data: 'Data-SIM',
	sim_card_service_type_twin: 'Tvilling-SIM',
	sim_card_physical_type: 'Fysisk SIM',
	sim_card_e_sim_type: 'eSIM',
	search: 'Søk',
	customer_search: 'Kundesøk',
	home: 'Hjem',
	replace_sim_card: 'Bytte ut SIM-kortet',
	change_product_option: 'Bytte produktalternativ',
	change_barring_option: 'Bytte sperringsalternativ',
	replace: 'Bytte',
	logout: 'Logg ut',
	order_history_signup_date: 'Dato',
	order_history_signup_id: 'ID',
	order_history_phone_number: 'Nummer',
	order_history_customer_name: 'Kundenavn',
	order_history_status: 'Status',
	order_history_contract_file: 'Kontrakt',
	sim_card_replacement_request_success_message: 'Utskifting av SIM-kort lyktes',
	subscriptions: 'Abonnementer',
	on: 'av',
	off: 'på',
	option_toggle_confirmation: 'Er du sikker på at du vil slå',
	order_under_processing: 'Bestilling under behandling',
	confirm_toggle: 'Bekreft',
	activation: 'aktivering',
	deactivation: 'deaktivering',
	cancel: 'avbryt',
	new_product: 'Nytt produkt',
	current_product: 'Nåværende produkt',
	change_product: 'Endre produkt',
	scheduled: 'Planlagt',
	cancel_product_change: 'Avbryt produktendring',
	confirm_cancel_product_change: 'Bekrefte kansellering av produktendring',
	binding: 'I avtaleperiode',
	sign_in: 'Logg inn',
	invoice_date: 'Fakturadato',
	due_date: 'Tidsfrist',
	payment_date: 'Betalingsdato',
	amount: 'Beløp',
	is_paid: 'Er betalt',
	invoice_nr: 'Fakturanummer',
	download: 'nedlasting',
	e_mail: 'E-post',
	address: 'Adresse',
	invoices: 'Fakturaer',
	invalid_login: 'Ugyldig brukernavn eller passord',
	page_not_found: 'Side ikke funnet',
	vat_incl : 'inkl. mva',
	vat_excl : 'ekskl. mva',
	error: 'Feil',
	send_invoice: 'Send faktura',
	send_invoice_success: 'Sending av faktura lyktes',
};

const RegexStringValidation = '^[!"#$%&\'()*+,./0-9:;<=>?@A-Z^_`a-z{|}~ÀÁÄÅÆÈÉÊËÏÖØÙÚÛÜßàáäåæèéêëöøùúûü -]+$';

export {
	FormName,
	ContractSigningInstructionsSendingFormName,
	KeyWord,
	Action,
	Auth,
	YupError,
	Variables,
	DistributionCode,
	RegexStringValidation,
};

