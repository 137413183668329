import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {Auth, Action, KeyWord} from "../constants";
import { Buffer } from 'buffer';

export const loginRequest = createAsyncThunk('user-api/login',async (data) => {
    let myHeaders = new Headers();
    const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
    const bodyData = data;
    myHeaders.append('Api-Token', encodedApiToken);
    myHeaders.append('Content-Type', 'application/json');
    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(bodyData),
    };

    return await fetch(Action.POST_LOGIN_ENDPOINT_URL,requestOptions)
        .then(response => response.json())
})

export const logoutRequest = createAsyncThunk('user-api/logout', async () => {
    let myHeaders = new Headers();
    let loggedUserData = localStorage.getItem("user");
    let loggedUser = JSON.parse(loggedUserData);
    const auth_token = loggedUser.auth_token;
    const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
    myHeaders.append('Authorization', 'Bearer ' + auth_token)
    myHeaders.append('Api-Token', encodedApiToken);
    myHeaders.append('Content-Type', 'application/json');
    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
    };

    return await fetch(Action.POST_LOGOUT_ENDPOINT_URL,requestOptions)
        .then((response) => response.json());
})

const initialState = {
    userData: null,
    originalResponse: {},
    isLoading: false,
    loginError: null,
    logoutError: null,
};

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setLoginError: (state,action) => {
            state.loginError = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginRequest.pending, (state) => {
                state.userData = null
                state.originalResponse = {}
                state.loginError = null
                state.isLoading = true
            })
            .addCase(loginRequest.fulfilled, (state, action) => {
                state.isLoading = false
                state.loginError = null
                if (action.payload?.error?.code) {
                    state.loginError = action.payload.error;
                } else if (action.payload?.message?.success && action.payload?.login ) {
                    state.originalResponse = action.payload
                    state.userData = action.payload.data
                    localStorage.setItem('user',
                        `{
                            "auth_token": "${action.payload.login.auth_token}",
                            "name": "${action.payload.login.firstname} ${action.payload.login.lastname}",
                            "email": "${action.payload.login.email}"
                        }`
                    );
                } else if (!action.payload?.message?.success) {
                    state.loginError = {
                        code: 200,
                        description: `Invalid username or password`,
                        translation: KeyWord.invalid_login,
                    }
                }
            })
            .addCase(loginRequest.rejected, (state, action) => {
                state.originalResponse = {}
                state.isLoading = false
                state.loginError = {
                    code: 500,
                    description: `Technical error: ${action.error.message}`,
                    translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${action.error.message}`,
                }
            })
            .addCase(logoutRequest.pending, (state) => {
                state.isLoading = true
            })
            .addCase(logoutRequest.fulfilled, (state, action) => {
                state.userData = null
                state.isLoading = false
                if (action.payload?.error?.code) {
                    state.logoutError = action.payload.error
                } else if (action.payload?.message?.success) {
                    state.originalResponse = {};
                    localStorage.clear();
                }
            })
            .addCase(logoutRequest.rejected, (state) => {
                state.isLoading = false
                state.userData = null
                localStorage.clear();
            })

    },
});

export const { setLoginError } = authenticationSlice.actions;

export default authenticationSlice.reducer;