import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setActive } from '../../features/customer/customerSubscriptionsSlice'
import { getCustomerRequest } from '../../features/customer/customerSlice';

export const CustomerSubscriptionItem = ({reference_id, phone_number, end_user, price_plan_name, status}) => {
	const { active } = useSelector((store)=>store.customerSubscriptionList)
	const dispatch = useDispatch();

	return (
		<>
			<div className={ reference_id === active ? 'subscription active margin-t-s':'subscription margin-t-s'}
				onClick = {() => {
					dispatch(setActive(reference_id));
					dispatch(getCustomerRequest({"reference_id": reference_id}));
				}}
			>

				<div className="subscription__user">
					<div className='subscription__user__name'>
						<div className='icon user --small' />
						<p>{ end_user }</p>
					</div>
					<h5>{ phone_number }</h5>
					<p className='price-plan-title'>{ price_plan_name }</p>
				</div>

				<div className={`subscription__icon icon ${status === 'scheduled'? 'pending' : status} --small`}></div>

			</div>
		</>
	);
};

export default CustomerSubscriptionItem;