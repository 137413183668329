import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Action, Auth } from "../../constants";
import { Buffer } from 'buffer';
import { getPricePlanRequest, setPricePlanError } from './customerPricePlanSlice';


export const putCanclePricePlanChangeRequest = createAsyncThunk('customer/putCancelPricePlanChange', async (token, thunkAPI) => {
	var myHeaders = new Headers();
	let loggedUserData = localStorage.getItem("user");
	let loggedUser = JSON.parse(loggedUserData);
	const auth_token = loggedUser.auth_token;
	const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
	myHeaders.append('Authorization', 'Bearer ' + auth_token)
	myHeaders.append('Api-Token', encodedApiToken)
	var requestOptions = {
		method: 'POST',
		headers: myHeaders
	};
	const result = await fetch(Action.PUT_CANCEL_PRICE_PLAN_CHANGE_URL+token,requestOptions)
	.then((response) => response.json());

	if (result.message && result.message.success) {
		thunkAPI.dispatch(getPricePlanRequest(token))
		.catch((error)=> {
			setPricePlanError({
				code: 500,
				description: `Technical error: ${error.message}.`,
				translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
			})
		});
	};

	return result;
})

const initialState = {
	showCancelPricePlanChangeModal: false,
	disabled: false,
	error: null,
	isLoading: false,
};

const cancelPricePlanChangeSlice = createSlice({
	name: 'cancelPricePlanChange',
	initialState,
	reducers: {
		setError: (state, action) => {
			state.error = action.payload
		},
		toggleCancelPricePlanChangeModal: (state) => {
			if (state.showCancelPricePlanChangeModal) {
				state.showCancelPricePlanChangeModal = false
				state.error = null
				state.isLoading = null
			} else {
				state.showCancelPricePlanChangeModal = true
			}
		}
	},
	extraReducers: (builder) => {
		builder.addCase(putCanclePricePlanChangeRequest.pending, (state) => {
			state.isLoading = true
			state.disabled = true
			state.error = null
		})
		.addCase(putCanclePricePlanChangeRequest.fulfilled, (state, action) => {
			state.isLoading = false
			state.disabled = false
			state.originalResponse = action.payload
			if (action.payload?.error?.code) {
				state.error = action.payload.error
			} else if (action.payload?.message?.success) {
				state.showCancelPricePlanChangeModal = false
			}
		})
		.addCase(putCanclePricePlanChangeRequest.rejected, (state, action) => {
			state.isLoading = false
			state.disabled = false
			state.error = {
				code: 500,
				description: `Technical error: ${action.error.message}`,
				translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${action.error.message}`,
			}
		})
	}
});

export const { setError, toggleCancelPricePlanChangeModal } = cancelPricePlanChangeSlice.actions;

export default cancelPricePlanChangeSlice.reducer;