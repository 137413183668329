import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest, setLoginError } from "../../features/authenticationSlice";
import { Form, Formik } from "formik";
import {FormName, KeyWord} from "../../constants";
import TextInput from "../form-components/text-input.component";
import LoaderSmall from "../loader/loader-small.component";

import LoginValidator from "../../client-side-validation/login-validator";
import {useNavigate} from "react-router-dom";

export const LoginPage = () => {

    const dispatch = useDispatch();
    const { isLoading, loginError } = useSelector((store)=>store.authentication);
    const navigate = useNavigate();

    const loginRequestHandler = (data) => {
         return dispatch(loginRequest(data));
    };

return (
    <>
        <div className='login'>
            <div className='login-form'>
                <div className="logo --small billity" ></div>
                <div className='login-form__center'>
                    <Formik
                        initialValues={{
                            login_username: '',
                            login_password: '',
                            gateway: 'customer-care',
                        }}
                        validationSchema={LoginValidator}
                        autocomplete='off'
                        onSubmit={
                            (values)=>{
                                const sendData = {
                                    username: values.login_username,
                                    password: values.login_password,
                                    gateway: values.gateway,
                                }

                                loginRequestHandler(sendData)
                                .catch((error) => {
                                    setLoginError({
                                        code: 500,
                                        description: `Technical error: ${error.message}.`,
                                        translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
                                    })
                                }).then(() => navigate('/'))
                            }
                        }
                    >
                        {({ values, status, errors, touched, isValid, submitCount, setFieldValue, handleSubmit, ...props }) => (
                            <Form>
                                <div>
                                    <h1>{KeyWord.sign_in}</h1>
                                    <div className='margin-t-l'>
                                        <TextInput
                                            label={FormName.login_username.label}
                                            disabled={isLoading}
                                            label_small={FormName.login_username.label_small}
                                            name={FormName.login_username.name}
                                            type='text'
                                            tool_tip={FormName.login_username.tool_tip}
                                        />
                                    </div>
                                    <div className='margin-t-l'>
                                        {
                                            <TextInput
                                                label={FormName.login_password.label}
                                                disabled={isLoading}
                                                label_small={FormName.login_password.label_small}
                                                name={FormName.login_password.name}
                                                type='password'
                                                tool_tip={FormName.login_password.tool_tip}
                                            />
                                        }
                                    </div>
                                    {
                                        loginError &&
                                        <div className='base-alert --error margin-t-l'>
                                            {loginError.translation ? loginError.translation : null}
                                        </div>
                                    }
                                    <LoaderSmall isEnabled={isLoading} extraClass='margin-t-l'/>
                                    <div className='one-button-row margin-t-s'>
                                        <button className='btn primary large right :after full-width'
                                                disabled={isLoading}
                                                type='submit'
                                        >
                                            {KeyWord.sign_in}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            <div className="login-side">
                <div className='center-content'>

                </div>
            </div>
        </div>

    </>
);
};

export default LoginPage;
