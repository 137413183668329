import * as React from 'react';
import { numberFormat } from '../../utils/helper-functions';
import { useSelector,useDispatch } from 'react-redux';
import { toggleOptionModal } from '../../features/customer/customerToggleOptionSlice';
import { KeyWord } from '../../constants';


export const CustomerOptions = ({ name, title, value, pending, product_option_type, price }) => {
	const dispatch = useDispatch();
	const { accountOwner } = useSelector((store)=>store.customer)

	return (
		<>
			<div key={name} className={ value? 'option active margin-t-s' : 'option margin-t-s'}>
				<div>
					<div className='inline'>
						<p className='name'>{title} </p>
						{
							(accountOwner.type === 'business' && product_option_type === 'sub_product') &&
							<p className='price'>&nbsp; {price?.incl_vat && `(${numberFormat(price?.incl_vat, 0, '', '')},-)`}</p>
						}
						{
							(accountOwner.type === 'private' && product_option_type === 'sub_product') &&
							<p className='price'>&nbsp; {price?.excl_vat && `(${numberFormat(price?.excl_vat, 0, '', '')},-)`}</p>
						}
					</div>
					{pending && <p className={'sub-text'}>{KeyWord.order_under_processing}</p>}
				</div>

				<button
					className={
						(value ? 'btn fab secondary small switch' : 'btn fab grey small switch')
						+ (pending ? ' disabled' : '')
					}
					disabled= {pending && true}
					onClick={
						() => {dispatch(toggleOptionModal({ "name": name , "title": title, "value": value, "option_type": product_option_type}))}
					} >
						{value? 'på' : 'av'}
				</button>
			</div>
		</>
	);
};

export default CustomerOptions;
