import * as React from 'react';
import {useNavigate} from "react-router-dom";
import {KeyWord} from "../../constants";
import {logoutRequest } from "../../features/authenticationSlice";
import {useDispatch, useSelector} from "react-redux";

export const NavMenuComponent = () => {
	let userData = JSON.parse(localStorage.getItem('user'));
	const { isLoading } = useSelector((store)=>store.authentication);
	let username = '';
	if (userData) {
		username = userData.name;
	}
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const logoutRequestHandler = async () =>{
		dispatch(logoutRequest()).then(() => navigate('/login'))
	}

	return (
		<div className='menubar'>
			<div className={`logo --small ${process.env.REACT_APP_CLIENT}`}>
			</div>
			<div className="navigation-links">
			</div>
			<div className="user-info-container">
				<div className="user-info margin-r-l">{username}</div>
				<button className='btn small primary switch'
						disabled={isLoading}
						onClick={()=>logoutRequestHandler()}
				>
					{KeyWord.logout}
				</button>
			</div>
		</div>
	);
};
