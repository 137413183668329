import * as React from 'react';
import { KeyWord } from '../../constants';
import { useSelector } from 'react-redux';
import { PricePlanTableRow } from './customer-price-plan-row.component';
import LoaderSmall from '../loader/loader-small.component';


export const GetCustomerSubscription = () => {
	const { customerData } = useSelector((store)=>store.customer)
	const { pricePlan, isLoading, error  } = useSelector((store)=>store.pricePlan)

	return (
		<>
			{
				customerData && !isLoading && !error &&
				<div className='checkout-content-box__customer margin-t-l'>
					<h3>{KeyWord.product}</h3>
					<div>
						<div className='table subscriptions-table'>
							<div className='table__heading'>
								<div className='table__cell'>{KeyWord.name}</div>
								<div className='table__cell'>{KeyWord.start_date}</div>
								<div className='table__cell'>{KeyWord.end_date}</div>
								<div className='table__cell'>Status</div>
								<div className='table__cell'></div>
							</div>
							{
								pricePlan &&
								pricePlan.map((item) => {
									return <PricePlanTableRow key={item.product_code} {...item}/>
								})
							}
						</div>
					</div>
				</div>
			}
			{
				isLoading &&
				<LoaderSmall isEnabled={isLoading} extraClass='margin-l-s'/>
			}
			{
				error &&
				<div className='base-alert --error margin-t-l'>
					{error?.translation ? error.translation : null}
				</div>
			}

		</>
	);
};

export default GetCustomerSubscription;
