import React from 'react';
import {createHashRouter, RouterProvider} from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
import CustomerData from "./components/get-customer-data/customer-data.component";
import LoginPage from "./components/login/login.comopnent";
import PageNotFound from "./components/misc/page-not-found.component"

const hashRouter = createHashRouter([
	{
		path: "/login",
		element: <LoginPage/>
	},
	{
		path: "*",
		element: <PageNotFound/>
	},
	{
		path: "/",
		element: <PrivateRoutes/>,
		children: [
			{
				index: true,
				element: <CustomerData/>
			},
		]
	},
]);

function App() {
	return <RouterProvider router={hashRouter}/>
};

export default App;
