import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Action, Auth } from '../../constants';
import { Buffer } from 'buffer';


export const getCustomerRequest = createAsyncThunk('customer/getCustomerData', async (data) => {
	let loggedUserData = localStorage.getItem("user");
	let loggedUser = JSON.parse(loggedUserData);
	const auth_token = loggedUser.auth_token;
	let myHeaders = new Headers();
	const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
	myHeaders.append('Authorization', 'Bearer ' + auth_token)
	myHeaders.append('Api-Token', encodedApiToken);
	myHeaders.append('Content-Type', 'application/json');
	const param = data.phone_number ? '?phone_number=' : '?reference_id=';
	const paramData = data.phone_number ? data.phone_number : data.reference_id;
	let requestOptions = {
		method: 'GET',
		headers: myHeaders
	};
	return await fetch(Action.GET_CUSTOMER_DATA_ENDPOINT_URL+param+paramData,requestOptions)
	.then((resp) => resp.json());
})

const initialState = {
	customerData: null,
	originalResponse: {},
	customerCurrentPricePlan: null,
	accountOwner: null,
	error: null,
	isLoading: false,
};

const customerDataSlice = createSlice({
	name: 'customer',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = action.payload
		},
		setError: (state, action)=>{
			state.error = action.payload
		},
		setEndDate: (state, action) => {
			state.customerCurrentPricePlan.end_date = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
		.addCase(getCustomerRequest.pending, (state) => {
			state.customerData = null
			state.originalResponse = {}
			state.error = null
			state.isLoading = true
		})
		.addCase(getCustomerRequest.fulfilled, (state, action) => {
			state.isLoading = false
			state.originalResponse = action.payload
			if (action.payload?.error?.code) {
				state.error = action.payload.error
			} else if (action.payload?.message?.success && action.payload?.data ) {
				state.customerData = action.payload.data
				state.customerCurrentPricePlan = {
					"price_plan_name": action.payload.data.price_plan_name,
					"status": action.payload.data.status,
					"start_date": action.payload.data.start_date,
					"end_date": action.payload.data.end_date
				}
				state.accountOwner = action.payload.data.account_owner
			}
		})
		.addCase(getCustomerRequest.rejected, (state, action) => {
			state.customerData = null
			state.originalResponse = {}
			state.isLoading = false
			state.error = {
				code: 500,
				description: `Technical error: ${action.error.message}`,
				translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${action.error.message}`,
			}
		})
	},
});

export const { setLoading, setError, setEndDate } = customerDataSlice.actions;

export default customerDataSlice.reducer;