import * as React from 'react';
import { toggleOptionModal } from '../../../features/customer/customerToggleOptionSlice';
import { useSelector, useDispatch } from 'react-redux';
import LoaderSmall from '../../loader/loader-small.component';
import { putToggleOptionRequest, setOptionError } from '../../../features/customer/customerToggleOptionSlice'
import { KeyWord } from '../../../constants';


export const OptionModalComponent = () => {
	const dispatch = useDispatch();
	const { showOptionModal, isLoading, error, data, disabled } = useSelector((store)=>store.toggleOption)
	const { customerData } = useSelector((store)=>store.customer)


	const optionToggleRequestHandler = async (data, reference_id) => {
		const requestData = {"type" : data.option_type, "name" : data.name, "value": !data.value};
		return dispatch(putToggleOptionRequest({"data": requestData, "reference_id": reference_id}))
		.catch((error)=> {
			setOptionError({
				code: 500,
				description: `Technical error: ${error.message}.`,
				translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
			})
		});
	}

	return (
		<div id="myModal" className={showOptionModal? "modal active" : "modal"}>
			<div className="modal-content small">
				<div className='modal-header'>
					<h5>{data?.title && data.title}</h5>
					<span className="close" onClick={()=>{dispatch(toggleOptionModal(null))}} >&times;</span>
				</div>
				<div className='modal-body text-center'>
					{
						!isLoading && !error &&
						<p className='confirmation-text margin-t-l'>{KeyWord.confirm_toggle} {data?.value? KeyWord.deactivation : KeyWord.activation} {data?.title && data.title}</p>
					}
					{
						isLoading &&
						<LoaderSmall isEnabled={isLoading} extraClass='margin-l-s'/>
					}
					{
						error &&
						<div className='base-alert --error margin-t-l'>
							{error?.translation ? error.translation : null}
						</div>
					}

					<div className='two-button-row margin-t-l'>
						<button
							disabled ={ disabled }
							className={'btn primary medium' + (disabled ? ' disabled' : '')}
							onClick={() => {
								/*optionToggleRequestHandler(data, customerData.reference_id);*/
								return;
							}}
						>
							{KeyWord.confirm_toggle}
						</button>
						<button className='btn outlined medium'
							onClick={()=>{dispatch(toggleOptionModal(null))}}
						>
							{KeyWord.cancel}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OptionModalComponent;
