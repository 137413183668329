import * as React from 'react';
import { KeyWord } from '../../constants';
import {useSelector} from 'react-redux';
import LoaderSmall from "../loader/loader-small.component";
import InvoiceTableRow from './customer-invoice-row.component';
import DownloadErrorModalComponent from './customer-modals/invoice-download-error-modal.component'
import InvoiceNotificationModalComponent from "./customer-modals/invoice-notification.component";

export const InvoiceTable = () => {
	const { invoiceList, isLoading, error } = useSelector((store) => store.invoices)
	const { accountOwner } = useSelector((store)=>store.customer)
	return (
		<>
			<h1>{ accountOwner.full_name }</h1>
			<div className='invoicee-info'>
				<div className='data-columns'>

					<div className='each-column'>
						<div className='data-columns'>
							<div className='icon calendar --small' />
							<div>
								<p className="title">{ KeyWord.e_mail }:</p>
								<p>{ accountOwner.contact_email }</p>
							</div>
						</div>
					</div>

					<div className='each-column'>
						<div className='data-columns'>
							<div className='icon calendar --small' />
							<div>
								<p className="title">{ KeyWord.phone_nr }:</p>
								<p>{ accountOwner.contact_phone_number }</p>
							</div>
						</div>
					</div>

					<div className='each-column'>
						<div className='data-columns'>
							<div className='icon calendar --small' />
							<div>
								<p className="title">{KeyWord.address}:</p>
								<p>{ accountOwner.address.postal_address }, {accountOwner.address.postal_code} { accountOwner.address.postal_city }</p>
							</div>
						</div>
					</div>

				</div>
			</div>
			<h2 className='margin-t-xl'>{ KeyWord.invoices }</h2>
			{
				isLoading &&
				<LoaderSmall isEnabled={isLoading} extraClass='margin-l-s'/>
			}
			{
				error &&
				<div className='base-alert --error margin-t-l'>
					{error?.translation ? error.translation : null}
				</div>
			}
			{
				invoiceList &&
				<div className='checkout-content-box__customer'>
					<div className='table'>
						<div className='table__heading'>
							<div className='table__cell'>{KeyWord.invoice_nr}</div>
							<div className='table__cell'>{KeyWord.amount} {`(${KeyWord.vat_incl})`}</div>
							<div className='table__cell'>{KeyWord.invoice_date}</div>
							<div className='table__cell'>{KeyWord.due_date}</div>
							<div className='table__cell'>{KeyWord.payment_date}</div>
							<div className='table__cell'></div>
							<div className='table__cell'></div>
						</div>
						{
							invoiceList.map((item) => {
								return <InvoiceTableRow key={item.invoice_reference_id} {...item} />
							})
						}
					</div>
				</div>
			}
			<DownloadErrorModalComponent />
			<InvoiceNotificationModalComponent />
		</>
	);
};

export default InvoiceTable;