import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Action, Auth } from "../../constants";
import { toggleProductOption } from './customerProductSlice';
import { toggleBarringOption } from './customerBarringSlice';
import { Buffer } from 'buffer';

export const putToggleOptionRequest = createAsyncThunk('customer/toggleOption', async (data, thunkAPI) => {
	let myHeaders = new Headers();
	let loggedUserData = localStorage.getItem("user");
	let loggedUser = JSON.parse(loggedUserData);
	const auth_token = loggedUser.auth_token;
	const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
	myHeaders.append('Authorization', 'Bearer ' + auth_token)
	myHeaders.append('Api-Token', encodedApiToken)
	myHeaders.append('Content-Type', 'application/json');
	const bodyData = data.data;
	let requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: JSON.stringify(bodyData)
	};

	const result = await fetch(Action.POST_CUSTOMER_TOGGLE_OPTIONS_ENDPOINT_URL+data.reference_id,requestOptions)
	.then((response) => response.json());

	if (result.message && result.message.success) {
		if (bodyData.type === 'service' || bodyData.type === 'sub_product') {
			thunkAPI.dispatch(toggleProductOption(bodyData.name))
		} else {
			thunkAPI.dispatch(toggleBarringOption(bodyData.name))
		}
		thunkAPI.dispatch(toggleOptionModal(null));
	};

	return result;
})

const initialState = {
	showOptionModal: false,
	data: null,
	isLoading: false,
	disabled: false,
	error: null,
};

const toggleOptionSlice = createSlice({
	name: 'toggleOption',
	initialState,
	reducers: {
		setOptionError: (state, action) => {
			state.error = action.payload;
			state.disabled = true;
		},
		toggleOptionModal: (state, action) => {
			if (state.showOptionModal){
				state.showOptionModal = false;
				state.data = null;
				state.isLoading = false;
				state.disabled = false;
				state.error = null;
			} else {
				state.showOptionModal = true;
				state.data = action.payload;
			}
		},
	},
	extraReducers: (builder) => {
		builder
		.addCase(putToggleOptionRequest.pending, (state) => {
			state.error = null;
			state.isLoading = true;
			state.disabled = true;
		})
		.addCase(putToggleOptionRequest.fulfilled, (state, action) => {
			state.isLoading = false;
			state.disabled = false;
			if (action.payload?.error?.code) {
				state.disabled = true;
				state.error = action.payload.error;
			}
		})
		.addCase(putToggleOptionRequest.rejected, (state, action) => {
			state.isLoading = false;
			state.disabled = true;
			state.error = {
				code: 500,
				description: `Technical error: ${action.error.message}`,
				translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${action.error.message}`,
			};
		})
	},
});

export const { toggleOptionModal, setOptionError } = toggleOptionSlice.actions;

export default toggleOptionSlice.reducer;