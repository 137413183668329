import * as React from 'react';
import { useSelector } from "react-redux";
import { KeyWord } from '../../constants';
import LoaderSmall from '../loader/loader-small.component';
import CustomerOptions from './customer-option.component';


export const CustomerProductOptions = () => {
const { productOptionList, isLoading, error } = useSelector((store)=>store.customerProductOptionList)


	return (
		<>
			<div className='margin-t-xl'>
				<h3>{KeyWord.product_options}</h3>
				{
					isLoading &&
					<LoaderSmall isEnabled={isLoading} extraClass='margin-l-s'/>
				}
				{
					error &&
					<div className='base-alert --error margin-t-l'>
						{error?.translation ? error.translation : null}
					</div>
				}
				{
					productOptionList &&
					<div className='option-list'>
					{
						productOptionList.map((item)=>{
							return <CustomerOptions key={item.name} { ...item }/>
						})
					}
					</div>
				}
			</div>
		</>
	);
};

export default CustomerProductOptions;