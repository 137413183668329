import * as Yup from 'yup';
import { YupError } from '../constants';
import { RegexStringValidation } from '../constants';

let regex = RegexStringValidation;

const LoginValidator = Yup.object().shape({
    login_username: Yup.string()
        .min(6, YupError.too_short)
        .max(150, YupError.too_long)
        .required(YupError.required)
        .matches(regex, YupError.invalid_characters),
    login_password: Yup.string()
        .min(6, YupError.too_short)
        .max(150, YupError.too_long)
        .matches(regex, YupError.invalid_characters)
        .required(YupError.required),
});

export default LoginValidator;
