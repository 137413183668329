import * as React from 'react';


export const CustomerUsageTableRow = ({ type, datetime, title, units, price, to_number }) => {

	// get the price with VAT and round it up to 2 decimals
	const { incl_vat } = price;
	const roundedPrice = Number(incl_vat).toFixed(2);

	// format the datetime string to somethiong more readable
	const formatDate = (datetimeString) => {
		const date = new Date(datetimeString);
		return new Intl.DateTimeFormat('nb-NO', {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: '2-digit',
			minute: '2-digit'
		}).format(date);
	};
	const formatedDate = formatDate(datetime);

	// format the units: voice (sec --> min) and gprs (bytes --> MB)
	const formatUnits = (type, units) => {
		if (type === 'voice') {
			const minutes = Math.floor(units / 60);
			const seconds = units % 60;
			return `${minutes} min ${seconds} sec`;
		} else if (type === 'gprs') {
			return (units / (1024 * 1024)).toFixed(2) + ' MB';
		} else {
			return units;
		}
	};
	const formatedUnits = formatUnits(type, units);

	return (
		<>
			<div className='table__row'>
				<div className='table__cell'>{formatedDate}</div>
				<div className='table__cell'>{type}</div>
				<div className='table__cell'>{title}</div>
				<div className='table__cell'>{to_number}</div>
				<div className='table__cell align-right'>{formatedUnits}</div>
				<div className='table__cell align-right'>{roundedPrice}</div>
			</div>
		</>
	);
};


