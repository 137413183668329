import * as React from 'react';


export const Pagination = ({ currentPage, itemsPerPage, totalItems, onPageChange }) => {

const totalPages = Math.ceil(totalItems / itemsPerPage);

	const handleButtonClick = (e, page) => {
		e.preventDefault();
		onPageChange(page);
	};

	const handlePrevButtonClick = (e) => {
		e.preventDefault();
		if (currentPage > 1) {
			onPageChange(currentPage - 1);
		}
	};

	const handleNextButtonClick = (e) => {
		e.preventDefault();
		if (currentPage < totalPages) {
			onPageChange(currentPage + 1);
		}
	};

	return (
		<div className="pagination margin-t-m">
			{totalItems > 0 ? (
				<>
					<button
						className="arrow--prev"
						onClick={handlePrevButtonClick}
						disabled={currentPage === 1}
					/>

					{[...Array(totalPages)].map((_, index) => (
						<button
							key={index}
							className={currentPage === index + 1 ? 'active' : ''}
							onClick={(e) => handleButtonClick(e, index + 1)}
						>
							{index + 1}
						</button>
					))}

					<button
						className="arrow--next"
						onClick={handleNextButtonClick}
						disabled={currentPage === totalPages}
					/>
				</>
			) : null}
		</div>
	);
};