import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Action,Auth } from '../../constants';
import { Buffer } from 'buffer';

export const invoiceListRequest = createAsyncThunk('customer/invoice-list', async (reference_id) => {

	let myHeaders = new Headers();
    let loggedUserData = localStorage.getItem("user");
    let loggedUser = JSON.parse(loggedUserData);
    const auth_token = loggedUser.auth_token;
    const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
    myHeaders.append('Authorization', 'Bearer ' + auth_token)
    myHeaders.append('Api-Token', encodedApiToken)
    let requestOptions = {
		method: 'GET',
		headers: myHeaders,
	};
	return await fetch(Action.GET_INVOICE_LIST_ENDPOINT_URL + reference_id, requestOptions)
	.then(resp => resp.json());
})

export const downloadLinkRequest = async (data) => {

    let myHeaders = new Headers();
    let loggedUserData = localStorage.getItem("user");
    let loggedUser = JSON.parse(loggedUserData);
    let reference_id = '?reference_id=' + data.reference_id;
    let invoice_reference_id = '&invoice_reference_id=' + data.invoice_reference_id;
    const auth_token = loggedUser.auth_token;
    const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
    myHeaders.append('Authorization', 'Bearer ' + auth_token)
    myHeaders.append('Api-Token', encodedApiToken)
    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
    };

    return await fetch(Action.GET_INVOICE_DOWNLOAD_ENDPOINT_URL + reference_id + invoice_reference_id, requestOptions)
}

const initialState = {
    invoiceList: null,
    downloadLink: null,
    originalResponse: {},
    error: null,
    downloadError: null,
    isLoading: false
};

const invoiceSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        setInvoiceError: (state, action) => {
            state.error = action.payload
        },
        setDownloadError: (state, action) => {
            state.downloadError = action.payload
        },
        closeDownloadErrorModal: (state) => {
            state.downloadError = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(invoiceListRequest.pending, (state) => {
            state.invoiceList = null
            state.originalResponse = {}
            state.isLoading = true
            state.error = null
        })
        .addCase(invoiceListRequest.fulfilled, (state, action) => {
            state.isLoading = false
            state.originalResponse = action.payload
            if (action.payload?.error?.code) {
                state.error = action.payload.error
            } else if (action.payload?.message?.success && action.payload?.data) {
                state.result = null
                state.invoiceList = action.payload.data
                state.error = null
            }
        })
        .addCase(invoiceListRequest.rejected, (state, action) => {
            state.invoiceList = null
            state.originalResponse = {}
            state.isLoading = false
            state.error = {
                code: 500,
                description: `Technical error: ${action.error.message}`,
                translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${action.error.message}`,
            }
        })
    }
});

export const { setInvoiceError,setDownloadError, closeDownloadErrorModal } = invoiceSlice.actions;

export default invoiceSlice.reducer;
