import * as React from 'react';
import { toggleProductModal, setNewProduct, postChangePricePlan, setChangePricePlanError } from '../../../features/customer/changeProductSlice';
import { useSelector, useDispatch } from 'react-redux';
import LoaderSmall from '../../loader/loader-small.component';
import { KeyWord, FormName } from '../../../constants';


export const ProductModalComponent = () => {
	const dispatch = useDispatch();
	const { showProductModal, isChangePricePlanLoading, pricePlanError, newProduct, disabled } = useSelector((store)=>store.changeProduct)
	const { possibleProductList, isPricePlanListLoading ,productListError } = useSelector((store)=>store.productList)
	const { binding } = useSelector((store)=>store.pricePlan)
	const { customerData, accountOwner } = useSelector((store)=>store.customer)

	const setNewProductHandler = (e) => {
		dispatch(setNewProduct(e.target.value));
	};

	const changePricePlanHandler = async () => {
		const requestData = {"product_code" : newProduct};
		return dispatch(postChangePricePlan({"data": requestData, "reference_id": customerData.reference_id}))
		.catch((error)=> {
			setChangePricePlanError({
				code: 500,
				description: `Technical error: ${error.message}.`,
				translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
			})
		});
	}

	return (
		<div id="myModal" className={showProductModal? "modal active" : "modal"}>
			<div className="modal-content small">
				<div className='modal-header'>
					<h5>{KeyWord.change_product}</h5>
					<span className="close" onClick={()=>{dispatch(toggleProductModal())}} >&times;</span>
				</div>
				<div className='modal-body'>

					{
						isPricePlanListLoading &&
						<LoaderSmall isEnabled={isPricePlanListLoading} extraClass='margin-l-s'/>
					}
					{
						possibleProductList &&
						<>
							<div>
								<p>{KeyWord.current_product}</p>
								<p className='confirmation-text margin-t-s'>{customerData && customerData.price_plan_name }</p>
							</div>
							<div className="form-fieldset margin-t-m">
								<div className={`form-field ${!possibleProductList ? 'validation-error' : ''}`}>
									<label>{FormName.change_product.label}</label>
									<select name="product_list" onChange={setNewProductHandler}>
										<option value="" key="-1">{FormName.change_product.placeholder}</option>
										{
											possibleProductList &&
											possibleProductList.map((product) => {
												const isDisabled = (product.unavailability_reason === 'current_price_plan');

												return (
													<option disabled={isDisabled}
															value={product.product_code}
															key={product.product_code}
													>
														{product.title} &nbsp; &nbsp; {accountOwner.type === 'business'? product.price.excl_vat : product.price.incl_vat },-
													</option>
												)
											})
										}
									</select>
								</div>
							</div>
						</>
					}
					{
						isChangePricePlanLoading &&
						<LoaderSmall isEnabled={isChangePricePlanLoading} extraClass='margin-l-s'/>
					}
					{
						pricePlanError &&
						<div className='base-alert --error margin-t-l'>
							{pricePlanError?.translation ? pricePlanError.translation : null}
						</div>
					}
					{
						productListError &&
						<div className='base-alert --error margin-t-l'>
							{productListError?.translation ? productListError.translation : null}
						</div>
					}
					{
						binding && binding.is_binding_period &&
						<>
							<div className='base-alert__outlined --info margin-t-m margin-b-s'>
								{`${KeyWord.binding} (dato: ${binding.binding_period_end_date})`}
							</div>
						</>
					}
					<div className='data-columns'>
						<div className='each-column'>
							<button
								disabled={disabled}
								className='btn primary medium'
								onClick={() => {
									changePricePlanHandler();
								}}
							>
								{KeyWord.change}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductModalComponent;
