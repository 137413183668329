import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	component: 'subscription',
};

const customerToggleSlice = createSlice({
  name: 'customerToggle',
  initialState,
  reducers: {
	 toggleSubscription: (state) => {
      state.component = 'subscription';
    },
    toggleSimCard: (state) => {
      state.component = 'sim';
    },
    toggleUsage: (state) => {
      state.component = 'usage';
    },
  },
});

export const { toggleSimCard, toggleSubscription, toggleUsage } = customerToggleSlice.actions;

export default customerToggleSlice.reducer;