import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Action, Auth } from '../../constants';
import { Buffer } from 'buffer';
import { getPricePlanRequest, setPricePlanError } from './customerPricePlanSlice';

export const postChangePricePlan = createAsyncThunk('customer/postChangePricePlan', async (data, thunkAPI) => {
	let myHeaders = new Headers();
	let loggedUserData = localStorage.getItem("user");
	let loggedUser = JSON.parse(loggedUserData);
	const auth_token = loggedUser.auth_token;
	const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
	myHeaders.append('Authorization', 'Bearer ' + auth_token)
	myHeaders.append('Api-Token', encodedApiToken)
	myHeaders.append('Content-Type', 'application/json');
	const bodyData = JSON.stringify(data.data);
	let requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: bodyData
	};
	const result = await fetch(Action.POST_CHANGE_PRICE_PLAN_URL+data.reference_id,requestOptions)
	.then((resp) => resp.json());

	if (result.message && result.message.success) {
		thunkAPI.dispatch(getPricePlanRequest(data.reference_id))
		.catch((error)=> {
			setPricePlanError({
				code: 500,
				description: `Technical error: ${error.message}.`,
				translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
			})
		});
	};

	return result
})

const initialState = {
	newProduct: null,
	showProductModal: false,
	isChangePricePlanLoading: false,
	pricePlanError: null
};

const changeProductSlice = createSlice({
	name: 'changeProduct',
	initialState,
	reducers: {
		toggleProductModal: (state) => {
			if (state.showProductModal){
				state.showProductModal = false;
				state.newProduct = null;
				state.pricePlanError = null;
			} else {
				state.showProductModal = true;
			}
		},
		setNewProduct: (state, action) => {
			state.newProduct = action.payload
		},
		setChangePricePlanError: (state, action) => {
			state.pricePlanError = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
		.addCase(postChangePricePlan.pending, (state) => {
			state.isChangePricePlanLoading = true
			state.pricePlanError = null
		})
		.addCase(postChangePricePlan.fulfilled, (state, action) => {
			state.isChangePricePlanLoading = false
			if (action.payload?.error?.code) {
				state.pricePlanError = action.payload.error
			} else if (action.payload?.message?.success) {
				state.showProductModal = false
			}
		})
		.addCase(postChangePricePlan.rejected, (state, action) => {
			state.isChangePricePlanLoading = false
			state.pricePlanError = {
				code: 500,
				description: `Technical error: ${action.error.message}`,
				translation: `Teknisk feil, prøsv igjen om en liten stund eller kontakt kundesenteret: ${action.error.message}`,
			}
		})
	},
});

export const { toggleProductModal, setNewProduct, setChangePricePlanError } = changeProductSlice.actions;

export default changeProductSlice.reducer;