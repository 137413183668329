import * as React from 'react';
import { CustomerUsageTableRow } from './customer-usage-table-row.component';
import { useSelector, useDispatch } from 'react-redux';
import LoaderSmall from '../loader/loader-small.component';
import {useEffect, useState} from 'react';
import { KeyWord } from '../../constants';
import { getCustomerUsageRequest, setUsageError } from '../../features/customer/customerUsageSlice';
import { Pagination } from '../navigation/pagination.component';



export const GetCustomerUsage = () => {
	const {customerUsageList, isLoading, error } = useSelector((store) => store.customerUsage);
	const {customerData} = useSelector((store)=>store.customer)
	const [type] = useState('');
	const [price] = useState('');
	const dispatch = useDispatch();

	// State for filter
	const [selectedType, setSelectedType] = useState('all');
	
	// State for pagination 
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 14;

	// State for hiding rows with no cost
    const [hideZeroCost, setHideZeroCost] = useState(false);

	useEffect(() => {
		const params = {"reference_id": customerData.reference_id}
		if (type) {
			params.type = type;
		}
		dispatch(getCustomerUsageRequest(params))
			.catch((error)=> {
				setUsageError({
					code: 500,
					description: `Technical error: ${error.message}.`,
					translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
				})
			});
	}, [type])

	// Event handler for filter
	const handleFilterChange = (e) => {
		setSelectedType(e.target.value);
	};

	// event handler for checkbox that hides zero cost items
	 const handleCheckboxChange = (e) => {
		setHideZeroCost(e.currentTarget.checked);
	};

	// Event handler for pagination
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	// If filter value is set to "all", filtered list is equal to original list, if not, the list is filtered based on selected value
	// If checkbox is checked, filtered list is filtered again to only show items with a cost
    const filteredCustomerUsageList = customerUsageList.filter(
		(item) => (selectedType === 'all' || item.type === selectedType) && (!hideZeroCost || item.price.incl_vat !== 0)
    );

	// Slice the usage list based on current page and items per page
	const paginatedUsageList = filteredCustomerUsageList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
	
	return (
		
		<>
			{
				isLoading &&
				<LoaderSmall isEnabled={isLoading} extraClass='margin-l-s'/>
			}
			{
				error &&
				<div className='base-alert --error margin-t-l'>
					{error?.translation ? error.translation : null}
				</div>
			}
			{
				customerUsageList &&
				<div className='checkout-content-box__customer margin-t-xl'>
					<div className="filter-bar margin-b-m">

						<div className="filter-bar__item">
							<span className="filter-label">
								{KeyWord.filter_usage}
							</span>
							
							<select value={selectedType} onChange={handleFilterChange}>
								<option value="all">All</option>
								<option value="gprs">GPRS</option>
								<option value="mms">MMS</option>
								<option value="sms">SMS</option>
								<option value="voice">Voice</option>
								<option value="other">Other</option>
							</select>
						</div>
						
						<div className="filter-bar__item">
							<div className="form-checkbox form-checkbox--large">
								<input type="checkbox" id="cost-checkbox" onChange={handleCheckboxChange} />
								<label htmlFor="cost-checkbox">{KeyWord.show_only_cost}</label>
							</div>
						</div>
						
					</div>
					<div className='table'>
						<div className='table__heading'>
							<div className='table__cell'>Added</div>
							<div className='table__cell'>Type</div>
							<div className='table__cell'>Description</div>
							<div className='table__cell'>To number</div>
							<div className='table__cell align-right'>Amount</div>
							<div className='table__cell align-right'>Cost</div>
						</div>
						{paginatedUsageList.length > 0 ? (
							// If the list is not empty, map through the list and render a row for each item
							paginatedUsageList.map((item) => {
								const randomId = `row-${Math.random().toString(36)}`;
								return <CustomerUsageTableRow key={randomId} {...item} />;
							})
							) : (
								// If the list is empty, show a proper message
								<div className="table__row full-width">
									<div className="empty-table">
										<span>{KeyWord.empty_usage}</span>
									</div>
								</div>
						)}
					</div>

					<Pagination
						currentPage={currentPage}
						itemsPerPage={itemsPerPage}
						totalItems={filteredCustomerUsageList.length}
						onPageChange={handlePageChange}
					/>

				</div>
			}
			
		</>
		
	);

};