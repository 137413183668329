import React from 'react';
import { useField } from 'formik';

const TextInput = ({ extraClass = '', label, placeholder, label_small, tool_tip, ...props }) => {
	const [field, meta] = useField(props);

	return (
		<div className={`form-field ${meta.touched && meta.error ? 'validation-error' : 'validation-success'} ${extraClass}`}>
			<label className='label'>
				{label}
				<span className='label-small-text'> {label_small}</span>
			</label>
			<input type='text' placeholder={placeholder} {...field} {...props} />
			{meta.touched && meta.error ? (
				<div className='inline-validation error'>{meta.error}</div>
			) : null}
		</div>
	);
};

export default TextInput;
