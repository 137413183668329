import * as React from 'react';
import { toggleSimModal } from '../../../features/customer/replaceSimCardSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import TextInput from '../../form-components/text-input.component';
import { FormName, KeyWord } from '../../../constants';
import SimCardReplacementFormValidator from '../../../client-side-validation/sim-card-replacement-form-validator'
import LoaderSmall from '../../loader/loader-small.component';
import { setReplaceSimError,simCardReplacementRequest } from '../../../features/customer/replaceSimCardSlice'


export const SimCardModalComponent = () => {
	const dispatch = useDispatch();
	const { current_sim_card_icc, showSimModal, result, isLoading, error } = useSelector((store)=>store.replaceSimCard)
	const { customerData } = useSelector((store)=>store.customer)

	const replaceSimCardSubmitHandler = async (data, token) => {
		return dispatch(simCardReplacementRequest({data:data, token:token}));
	};

	return (
		<div id="myModal" className={showSimModal? "modal active" : "modal"}>
			<div className="modal-content small">
				<div className='modal-header'>
					<h5>{KeyWord.replace_sim_card}</h5>
					<span className="close" onClick={()=>{dispatch(toggleSimModal(null))}} >&times;</span>
				</div>
				<div className='modal-body'>
					{
						<Formik
							initialValues={{
								sim_card_type: 'regular',
								current_sim_card_icc: current_sim_card_icc.icc_id,
								new_sim_card_icc: '',
								activation_email: customerData?.account_owner?.contact_email
									? customerData.account_owner.contact_email
									: ''
							}}
							validationSchema={SimCardReplacementFormValidator}
							autocomplete='off'

							onSubmit={
								(values)=>{
									const sendData = {
										sim_card_type: values.sim_card_type,
										current_sim_card_icc: values.current_sim_card_icc,
										new_sim_card_icc: values.new_sim_card_icc,
										activation_email: values.activation_email,
									}


									replaceSimCardSubmitHandler(sendData, customerData.customer_api_token)
									.catch((error) => {
										setReplaceSimError({
											code: 500,
											description: `Technical error: ${error.message}.`,
											translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
										})
									})
								}
							}
						>
							{({ values, status, errors, touched, isValid, submitCount, setFieldValue, handleSubmit, ...props }) => (
								<Form>
									<div>
										<div className='form-fieldset '>
											<div role="group" aria-labelledby="sim-card-type-group" className='form-radio-button-group'>
												<div className='form-radio-button'>
													<Field type="radio" id="sim_card_type_physical" disabled={isLoading} name="sim_card_type" value="regular" checked="checked"/>
													<label htmlFor="sim_card_type_physical">
														{KeyWord.sim_card_physical_type}
													</label>
												</div>
												<div className='form-radio-button'>
													<Field type="radio" id="sim_card_type_e_sim" disabled={isLoading} name="sim_card_type" value="e-sim" />
													<label htmlFor="sim_card_type_e_sim">
														{KeyWord.sim_card_e_sim_type}
													</label>
												</div>
											</div>
										</div>
										<div className='margin-t-l'>
											<TextInput
												label={FormName.current_sim_card_icc.label}
												disabled={isLoading}
												label_small={FormName.current_sim_card_icc.label_small}
												name={FormName.current_sim_card_icc.name}
												type='text'
												tool_tip={FormName.current_sim_card_icc.tool_tip}
											/>
										</div>
										<div className='margin-t-l'>
											{
												values.sim_card_type === "regular" ?
												<TextInput
													label={FormName.new_sim_card_icc.label}
													disabled={isLoading}
													label_small={FormName.new_sim_card_icc.label_small}
													name={FormName.new_sim_card_icc.name}
													type='text'
													tool_tip={FormName.new_sim_card_icc.tool_tip}
												/> :
													process.env.REACT_APP_CLIENT === 'smbmobil' &&
													<TextInput
													label={FormName.activation_email.label}
													disabled={isLoading}
													label_small={FormName.activation_email.label_small}
													name={FormName.activation_email.name}
													type='email'
													tool_tip={FormName.activation_email.tool_tip}
													/>
											}
										</div>
										{
											error &&
											<div className='base-alert --error margin-t-l'>
												{error.translation ? error.translation : null}
											</div>
										}
										{
											result &&
											<div className="base-alert --success margin-t-l">
												{KeyWord.sim_card_replacement_request_success_message}
											</div>
										}
										<LoaderSmall isEnabled={isLoading} extraClass='margin-t-l'/>
										<div className='one-button-row margin-t-s'>
											<button className='btn primary medium refresh'
												disabled={isLoading}
												type='submit'
											>
												{KeyWord.replace}
											</button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					}
				</div>
			</div>
		</div>
	);
};

export default SimCardModalComponent;