import * as React from 'react';
import { useSelector } from "react-redux";
import { KeyWord } from '../../constants';
import LoaderSmall from '../loader/loader-small.component';
import CustomerOptions from './customer-option.component';

export const CustomerBarringOptions = () => {
const { barringOptionList, isLoading, error } = useSelector((store)=>store.customerBarringOptionList)
const optionType = { optionType: "barring"};

	return (
		<>
			<div className='checkout-content-box__customer margin-t-xl'>
				<h3>{KeyWord.barring_options}</h3>
				{
					isLoading &&
					<LoaderSmall isEnabled={isLoading} extraClass='margin-l-s'/>
				}
				{
					error &&
					<div className='base-alert --error margin-t-l'>
						{error?.translation ? error.translation : null}
					</div>
				}
				{
					barringOptionList &&
					<div className='option-list'>
						{
							barringOptionList.map((item)=>{
								return <CustomerOptions key={item.name} {...item} {...optionType}/>
							})
						}
					</div>
				}
			</div>
		</>
	);
};

export default CustomerBarringOptions;