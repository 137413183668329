import * as React from 'react';
import { KeyWord } from '../../constants';
import { CustomerSimTableRow } from './customer-sim-table-row.component';
import { useSelector } from 'react-redux';
import { SimCardModalComponent } from './customer-modals/sim-card-modal.component'
import LoaderSmall from '../loader/loader-small.component';

export const GetCustomerSim = () => {
	const { customerSimList, isLoading, error } = useSelector((store) => store.customerSimList)
	const { showSimModal } = useSelector((store) => store.replaceSimCard)
	return (
		<>
			{
				isLoading &&
				<LoaderSmall isEnabled={isLoading} extraClass='margin-l-s'/>
			}
			{
				error &&
				<div className='base-alert --error margin-t-l'>
					{error?.translation ? error.translation : null}
				</div>
			}
			{
				customerSimList &&
				<div className='checkout-content-box__customer margin-t-xl'>
					<div className='table'>
						<div className='table__heading'>
							<div className='table__cell'>Type</div>
							<div className='table__cell'>Format</div>
							<div className='table__cell'>ICCID</div>
							<div className='table__cell'>IMSI</div>
							<div className='table__cell'>MSISDN</div>
							<div className='table__cell'>{KeyWord.new_sim_card_reserved}</div>
							<div className='table__cell'>PIN 1</div>
							<div className='table__cell'>PUK 1</div>
							<div className='table__cell'>{KeyWord.edit}</div>
						</div>
						{
							customerSimList.map((item) => {
								return <CustomerSimTableRow key={item.number} {...item} />
							})
						}
					</div>
				</div>
			}
			{
				showSimModal &&
				<SimCardModalComponent />
			}
		</>
	);
};

export default GetCustomerSim;
