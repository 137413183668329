import { configureStore, combineReducers } from '@reduxjs/toolkit';
import getCustomerUsageReducer from '../features/customer/customerUsageSlice';
import getCustomerReducer from '../features/customer/customerSlice';
import getCustomerToggleReducer from '../features/customer/customerToggleSlice';
import getCustomerSimReducer from '../features/customer/customerSimSlice';
import customerProductOptionReducer from '../features/customer/customerProductSlice';
import customerBarringOptionReducer from '../features/customer/customerBarringSlice';
import customerSubscriptionReducer from '../features/customer/customerSubscriptionsSlice';
import replaceSimCardReducer from '../features/customer/replaceSimCardSlice';
import toggleOptionReducer from '../features/customer/customerToggleOptionSlice';
import getPossibleProductListReducer from '../features/customer/getProductListSlice';
import changeProductReducer from '../features/customer/changeProductSlice';
import customerPricePlanReducer from '../features/customer/customerPricePlanSlice';
import cancelPricePlanChangeReducer from '../features/customer/cancelPricePlanChangeSlice';
import authenticationReducer from '../features/authenticationSlice';
import invoiceReducer from '../features/customer/customerInvoicesSlice'
import invoiceNotificationReducer from '../features/customer/invoiceNotificationSlice'

export const combineReducer = combineReducers({
	customerUsage: getCustomerUsageReducer,
	customer: getCustomerReducer,
	customerToggle: getCustomerToggleReducer,
	customerSimList: getCustomerSimReducer,
	customerProductOptionList: customerProductOptionReducer,
	customerBarringOptionList: customerBarringOptionReducer,
	customerSubscriptionList: customerSubscriptionReducer,
	replaceSimCard: replaceSimCardReducer,
	toggleOption: toggleOptionReducer,
	productList: getPossibleProductListReducer,
	changeProduct: changeProductReducer,
	pricePlan: customerPricePlanReducer,
	cancelPricePlanChange: cancelPricePlanChangeReducer,
	authentication: authenticationReducer,
	invoices: invoiceReducer,
	invoiceNotification: invoiceNotificationReducer
});

const rootReducer = (state, action) => {
	if (action.type === 'user-api/logout/fulfilled') {
		state = undefined;
	}
	return combineReducer(state, action);
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
