import * as Yup from 'yup';
import { YupError } from '../constants';
import { RegexStringValidation } from '../constants';

let regex = RegexStringValidation;

const SimCardReplacementFormValidator = Yup.object().shape({
	sim_card_type: Yup.string()
		.required()
		.matches(regex, YupError.invalid_characters)
		.oneOf(['regular' , 'e-sim']),
	current_sim_card_icc: Yup.string()
		.min(18, YupError.too_short)
		.max(22, YupError.too_long)
		.matches(regex, YupError.invalid_characters)
		.required(YupError.required),
	new_sim_card_icc: Yup.string()
		.min(18, YupError.too_short)
		.max(22, YupError.too_long)
		.matches(regex, YupError.invalid_characters)
		.when('sim_card_type', {
			is: 'regular',
			then: Yup.string().required(YupError.required),
		}),
	activation_email: Yup.string()
		.email(YupError.invalid_email)
		.matches(regex, YupError.invalid_characters)
		.when('sim_card_type', {
			is: 'e-sim',
			then: Yup.string().required(YupError.required),
		}),
});

export default SimCardReplacementFormValidator;
