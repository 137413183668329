import * as Yup from 'yup';
import { YupError } from '../constants';
import { RegexStringValidation } from '../constants';

let regex = RegexStringValidation;

const invoiceNotificationFormValidator = Yup.object().shape({
    notification_method: Yup.string()
        .required()
        .matches(regex, YupError.invalid_characters)
        .oneOf(['email' , 'sms', 'all']),
    email: Yup.string()
        .email(YupError.invalid_email)
        .matches(regex, YupError.invalid_characters)
        .when('notification_type', {
            is: 'email',
            then: Yup.string().required(YupError.required),
        })
        .when('notification_type', {
            is: 'all',
            then: Yup.string().required(YupError.required),
        }),
    phone_number: Yup.string()
        .matches(/^[0-9]+$/,YupError.invalid_characters)
        .min(8, YupError.too_short)
        .max(8, YupError.too_long)
        .required(YupError.required)
        .when('notification_type', {
            is: 'sms',
            then: Yup.string().required(YupError.required),
        })
        .when('notification_type', {
            is: 'all',
            then: Yup.string().required(YupError.required),
        }),
});

export default invoiceNotificationFormValidator;
