import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KeyWord } from '../../../constants';
import { closeDownloadErrorModal } from '../../../features/customer/customerInvoicesSlice'

export const DownloadErrorModalComponent = () => {
    const dispatch = useDispatch();
    const { downloadError } = useSelector((store)=>store.invoices);


    return (
        <div id="myModal" className={downloadError? "modal active" : "modal"}>
            <div className="modal-content small">
                <div className='modal-error-header'>
                    <h5>{KeyWord.error}</h5>
                    <span className="close" onClick={()=>{dispatch(closeDownloadErrorModal())}} >&times;</span>
                </div>
                <div className='modal-body text-center'>

                    {
                        downloadError &&
                        <div className='base-alert --error margin-t-l'>
                            {downloadError?.translation ? downloadError.translation : null}
                        </div>
                    }

                    <div className='two-button-row margin-t-l'>
                        <button className='btn outlined medium'
                                onClick={()=>{dispatch(closeDownloadErrorModal())}}
                        >
                            {KeyWord.close}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DownloadErrorModalComponent;