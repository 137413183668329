import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Action, Auth } from "../../constants";
import { Buffer } from 'buffer';

export const getCustomerSimRequest = createAsyncThunk('customer/getCustomerSims', async (reference_id) => {
	let myHeaders = new Headers();
	let loggedUserData = localStorage.getItem("user");
	let loggedUser = JSON.parse(loggedUserData);
	const auth_token = loggedUser.auth_token;
	const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
	myHeaders.append('Authorization', 'Bearer ' + auth_token)
	myHeaders.append('Api-Token', encodedApiToken)
	let requestOptions = {
		method: 'GET',
		headers: myHeaders
	};
	return await fetch(Action.GET_CUSTOMER_SIMS_ENDPOINT_URL+reference_id,requestOptions)
	.then((response) => response.json())
})

const initialState = {
	customerSimList: null,
	originalResponse: {},
	error: null,
	isLoading: false,
};

const customerSimSlice = createSlice({
	name: 'customerSimList',
	initialState,
	reducers: {
		setSimError: (state, action) => {
			state.error =  action.payload
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getCustomerSimRequest.pending, (state) => {
			state.customerSimList = null
			state.originalResponse = {}
			state.isLoading = true
			state.error = null
		})
		.addCase(getCustomerSimRequest.fulfilled, (state, action) => {
			state.isLoading = false
			state.originalResponse = action.payload
			if (action.payload?.error?.code) {
				state.error = action.payload.error
			} else if (action.payload?.message?.success && action.payload?.data ) {
				state.customerSimList = action.payload.data
			}
		})
		.addCase(getCustomerSimRequest.rejected, (state, action) => {
			state.isLoading = false
			state.customerSimList = null
			state.originalResponse = {}
			state.error = {
				code: 500,
				description: `Technical error: ${action.error.message}`,
				translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${action.error.message}`,
			}
		})
	}
});

export const { setSimError } = customerSimSlice.actions;

export default customerSimSlice.reducer;