import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Action, Auth } from "../../constants";
import { Buffer } from 'buffer';
import { useState, useEffect } from 'react';

export const getCustomerUsageRequest = createAsyncThunk('customer/getCustomerUsage', async (params) => {
	let myHeaders = new Headers();
	let loggedUserData = localStorage.getItem("user");
	let loggedUser = JSON.parse(loggedUserData);
	const auth_token = loggedUser.auth_token;
	const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
	myHeaders.append('Authorization', 'Bearer ' + auth_token)
	myHeaders.append('Api-Token', encodedApiToken)
	let requestOptions = {
		method: 'GET',
		headers: myHeaders
	};
	const paramsString = new URLSearchParams(params).toString();

	return await fetch(Action.GET_CUSTOMER_USAGE_ENDPOINT_URL + '?' + paramsString, requestOptions)
	.then((response) => response.json())
})

const initialState = {
	customerUsageList: [],
	originalResponse: {},
	error: null,
	isLoading: false,
};

const customerUsageSlice = createSlice({
	name: 'customerUsageList',
	initialState,
	reducers: {
		setUsageError: (state, action) => {
			state.error =  action.payload
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getCustomerUsageRequest.pending, (state) => {
			state.customerUsageList = []
			state.originalResponse = {}
			state.isLoading = true
			state.error = null
		})
		.addCase(getCustomerUsageRequest.fulfilled, (state, action) => {
			state.isLoading = false
			state.originalResponse = action.payload
			if (action.payload?.error?.code) {
				state.error = action.payload.error
			} else if (action.payload?.message?.success && action.payload?.data ) {

				let preparedList = [];

				// Get all record lists from the response
				const recordLists = {
					gprs: action.payload.data.gprs?.record_list,
					mms: action.payload.data.mms?.record_list,
					sms: action.payload.data.sms?.record_list,
					voice: action.payload.data.voice?.record_list,
					other: action.payload.data.other?.record_list,
				};

				// Merge all record lists into one
				Object.entries(recordLists).forEach(([type, recordList]) => {
					if (recordList?.length > 0) {
						preparedList.push(...recordList.map((item) => ({ ...item, type })));
					}
				});

				// Sort the record lists by datetime
				preparedList.sort((a, b) => {
					if (a.datetime < b.datetime) {
						return 1;
					} else if (a.datetime > b.datetime) {
						return -1;
					} else {
						return 0;
					}
				});

				// Set the list as state
				state.customerUsageList = preparedList
			}
		})
		.addCase(getCustomerUsageRequest.rejected, (state, action) => {
			state.isLoading = false
			state.customerUsageList = []
			state.originalResponse = {}
			state.error = {
				code: 500,
				description: `Technical error: ${action.error.message}`,
				translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${action.error.message}`,
			}
		})
	}
});

export const { setUsageError } = customerUsageSlice.actions;

export default customerUsageSlice.reducer;