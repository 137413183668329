import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCustomerSimRequest } from './customerSimSlice';
import { setSimError } from "./customerSimSlice";
import { Action,Auth } from '../../constants';
import { Buffer } from 'buffer';


export const simCardReplacementRequest = createAsyncThunk('customer/replaceSimCard', async (data, thunkAPI) => {
	let myHeaders = new Headers();
	let loggedUserData = localStorage.getItem("user");
	let loggedUser = JSON.parse(loggedUserData);
	const auth_token = loggedUser.auth_token;
	const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
	myHeaders.append('Authorization', 'Bearer ' + auth_token)
	myHeaders.append('Api-Token', encodedApiToken)
	myHeaders.append('Content-Type', 'application/json');
	const bodyData = JSON.stringify(data.data);
	let requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: bodyData,
	};
	const result = await fetch(Action.REPLACE_SIM_CARD_ENDPOINT_URL + data.token, requestOptions)
	.then((resp) => resp.json());

	if (result.message && result.message.success) {
		thunkAPI.dispatch(getCustomerSimRequest(data.token))
		.catch((error)=> {
			setSimError({
				code: 500,
				description: `Technical error: ${error.message}.`,
				translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
			})
		});
	};

	return result;
})

const initialState = {
	result: null,
	showSimModal: false,
	current_sim_card_icc: null,
	isLoading: false,
	error: null
};

const replaceSimCardSlice = createSlice({
	name: 'replaceSimCard',
	initialState,
	reducers: {
		toggleSimModal: (state, action) => {
			if (state.showSimModal){
				state.showSimModal = false;
				state.current_sim_card_icc = null;
				state.error = null;
				state.result = null;
			} else {
				state.current_sim_card_icc = action.payload;
				state.showSimModal = true;
			}
		},
		setReplaceSimError: (state, action) => {
			state.error = action.payload
		}
	},
	extraReducers: (builder) => {
		builder.addCase(simCardReplacementRequest.pending, (state) => {
			state.result = null
			state.isLoading = true
			state.error = null
		})
		.addCase(simCardReplacementRequest.fulfilled, (state, action) => {
			state.isLoading = false
			state.originalResponse = action.payload
			if (action.payload?.error?.code) {
				state.error = action.payload.error
			} else if (action.payload?.message?.success) {
				state.result = null
				state.showSimModal = false
				state.current_sim_card_icc = null
			}
		})
		.addCase(simCardReplacementRequest.rejected, (state, action) => {
			state.result = null
			state.isLoading = false
			state.error = {
				code: 500,
				description: `Technical error: ${action.error.message}`,
				translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${action.error.message}`,
			}
		})
	}
});

export const { setReplaceSimError, toggleSimModal } = replaceSimCardSlice.actions;

export default replaceSimCardSlice.reducer;
