import { Outlet, useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import React, {useEffect} from 'react';

const PrivateRoutes = () => {
    let { userData } = useSelector((store)=>store.authentication)
    const navigate = useNavigate();

    useEffect(() => {
        let data = localStorage.getItem("user");
        let user = JSON.parse(data);

         if ( !user || !user.auth_token ) {
             navigate("/login");
         }

        if ( user && user.auth_token ) {
            navigate("/");
        }

    }, [userData]);

    return (
        <>
           <Outlet />
        </>
    );
}

export default PrivateRoutes;
