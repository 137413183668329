import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Action,Auth } from '../../constants';
import { Buffer } from 'buffer';

export const invoiceNotificationRequest = createAsyncThunk('customer/resend-invoice', async ({data}) => {

    let myHeaders = new Headers();
    let loggedUserData = localStorage.getItem("user");
    let loggedUser = JSON.parse(loggedUserData);
    const auth_token = loggedUser.auth_token;
    const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
    myHeaders.append('Authorization', 'Bearer ' + auth_token)
    myHeaders.append('Api-Token', encodedApiToken)
    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data)
    };
    return await fetch(Action.POST_RESEND_INVOICE_ENDPOINT_URL, requestOptions)
        .then(resp => resp.json());
})

const initialState = {
    result: null,
    showInvoiceNotificationModal: false,
    invoice_reference_id: null,
    isLoading: false,
    error: null
};

const invoiceSlice = createSlice({
    name: 'invoiceNotification',
    initialState,
    reducers: {
        toggleInvoiceNotificationModal: (state,action) => {
            if (state.showInvoiceNotificationModal) {
                state.showInvoiceNotificationModal = false;
                state.invoice_reference_id = null;
                state.result = null;
                state.isLoading = false;
                state.error = null;
            } else {
                state.invoice_reference_id = action.payload;
                state.showInvoiceNotificationModal = true;
            }
        },
        setError: (state, action) => {
            state.error = action.payload.error;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(invoiceNotificationRequest.pending, (state) => {
            state.invoiceList = null
            state.result = null
            state.isLoading = true
            state.error = null
        })
            .addCase(invoiceNotificationRequest.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload?.error?.code) {
                    state.error = action.payload.error
                } else if (action.payload?.message?.success) {
                    state.result = action.payload.message.success
                    state.error = null
                }
            })
            .addCase(invoiceNotificationRequest.rejected, (state, action) => {
                state.result = null
                state.isLoading = false
                state.error = {
                    code: 500,
                    description: `Technical error: ${action.error.message}`,
                    translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${action.error.message}`,
                }
            })
    }
});

export const { setError, toggleInvoiceNotificationModal } = invoiceSlice.actions;

export default invoiceSlice.reducer;
