import * as React from 'react';
import { KeyWord } from '../../constants';
import { toggleSimModal } from '../../features/customer/replaceSimCardSlice';
import { useDispatch } from 'react-redux';

export const CustomerSimTableRow = ({ icc, imsi, number, pin, puk, new_sim_card_reserved, sim_card_service_type_keyword, sim_card_type_keyword }) => {
	const dispatch = useDispatch();

	return (
		<>
			<div className='table__row'>
				<div className='table__cell'>{KeyWord[sim_card_service_type_keyword]}</div>
				<div className='table__cell'>{KeyWord[sim_card_type_keyword]}</div>
				<div className='table__cell'>{icc}</div>
				<div className='table__cell'>{imsi}</div>
				<div className='table__cell'>{number}</div>
				<div className='table__cell'>{new_sim_card_reserved? 'Ja' : 'Nei'}</div>
				<div className='table__cell'>{pin}</div>
				<div className='table__cell'>{puk}</div>
				{
					<div className='table__cell'>
						<button className='btn primary small refresh'
						onClick={()=>{dispatch(toggleSimModal({"icc_id": icc}))}}
						>
							{KeyWord.replace}
						</button>
					</div>
				}
			</div>
		</>
	);
};
