import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {Action, Auth} from '../../constants';
import {Buffer} from 'buffer';

export const getPossibleProductList = createAsyncThunk('customer/getPossibleProductList', async (data) => {
    let myHeaders = new Headers();
    let loggedUserData = localStorage.getItem('user');
    let loggedUser = JSON.parse(loggedUserData);
    const auth_token = loggedUser.auth_token;
    const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
    myHeaders.append('Authorization', 'Bearer ' + auth_token);
    myHeaders.append('Api-Token', encodedApiToken);
    const paramData = data.reference_id;
    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
    };
    return await fetch(Action.GET_POSSIBLE_PRODUCT_LIST_URL + paramData, requestOptions).then((resp) => resp.json());
});

const initialState = {
    possibleProductList: null,
    originalResponse: {},
    isPricePlanListLoading: false,
    productListError: null,
};

const getPossibleProductListSlice = createSlice({
    name: 'productList',
    initialState,
    reducers: {
        setProductListError: (state, action) => {
            state.productListError = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPossibleProductList.pending, (state) => {
            state.possibleProductList = null;
            state.originalResponse = {};
            state.productListError = null;
            state.isPricePlanListLoading = true;
        }).addCase(getPossibleProductList.fulfilled, (state, action) => {
            state.originalResponse = action.payload;
            state.isPricePlanListLoading = false;
            if (action.payload?.error?.code) {
                state.productListError = action.payload.error;
            } else if (action.payload?.message?.success && action.payload?.data) {
                state.possibleProductList = action.payload.data;
            }
        }).addCase(getPossibleProductList.rejected, (state, action) => {
            state.possibleProductList = null;
            state.originalResponse = {};
            state.isPricePlanListLoading = false;
            state.productListError = {
                code: 500,
                description: `Technical error: ${action.error.message}`,
                translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${action.error.message}`,
            };
        });
    },
});

export const {setProductListError} = getPossibleProductListSlice.actions;

export default getPossibleProductListSlice.reducer;
