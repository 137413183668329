import * as React from 'react';
import { KeyWord } from '../../constants';
import { useSelector } from 'react-redux';

export const GetCustomerDataPerson = () => {
	const { customerData,customerCurrentPricePlan } = useSelector((store)=>store.customer)
	return (
		<>
		{
			customerData &&
			<div>
				<div className='checkout-content-box__customer data-columns'>
					<div className='data-columns'>
						<div className='each-column'>
							<div className='data-columns'>
								<div className='icon user --small' />
								<h5 className="nowrap">{ customerData.end_user_name }</h5>
							</div>
							<h2>{ customerData.number }</h2>
						</div>
						<div className='data-columns customer-info'>
							<div className='each-column'>
								<div className='data-columns'>
									<div className='icon phone --small' />
									<div>
										<p className='title'>{KeyWord.subscription}: </p>
										<p>{ customerCurrentPricePlan.price_plan_name? customerCurrentPricePlan.price_plan_name : 'N/A' }</p>
									</div>
								</div>
							</div>

							<div className='each-column'>
								<div className='data-columns'>
									<div className={`icon ${customerData.status} --small`} />
									<div>
										<p className='title'>Status: </p>
										<p>{KeyWord[customerData.status]}</p>
									</div>
								</div>
							</div>

							<div className='each-column'>
								<div className='data-columns'>
									<div className='icon calendar --small' />
									<div>
										<p className='title'>{KeyWord.start_date}: </p>
										<p>{ customerCurrentPricePlan.start_date? customerCurrentPricePlan.start_date : 'N/A'  }</p>
									</div>
								</div>
							</div>

							<div className='each-column'>
								<div className='data-columns'>
									<div className='icon calendar --small' />
									<div>
										<p className='title'>{KeyWord.end_date}: </p>
										<p>{ customerCurrentPricePlan.end_date? customerCurrentPricePlan.end_date : 'N/A' }</p>
									</div>
								</div>
							</div>


		

							{
								/*
								<div className='each-column margin-t-l'>
									<button className='btn primary small edit'>
										{KeyWord.edit}
									</button>
								</div>
								<div className='each-column margin-t-l'>
									<button className='btn light small delete'>
										{KeyWord.delete}
									</button>
								</div>
								*/
							}
						</div>
					</div>
				</div>
			</div>
			}
		</>
	);
};

export default GetCustomerDataPerson;