import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import TextInput from '../../form-components/text-input.component';
import { FormName, KeyWord } from '../../../constants';
import invoiceNotificationFormValidator from '../../../client-side-validation/invoice-notification-form-validator'
import { invoiceNotificationRequest, toggleInvoiceNotificationModal, setError } from '../../../features/customer/invoiceNotificationSlice'
import LoaderSmall from '../../loader/loader-small.component';



export const InvoiceNotificationModalComponent = () => {
    const dispatch = useDispatch();
    const { invoice_reference_id, showInvoiceNotificationModal, result, isLoading, error } = useSelector((store)=>store.invoiceNotification)
    const { accountOwner } = useSelector((store)=>store.customer)

    const invoiceNotificationSubmitHandler = async (data) => {
        return dispatch(invoiceNotificationRequest({data}));
    };

    return (
        <div id="myModal" className={showInvoiceNotificationModal? "modal active" : "modal"}>
            <div className="modal-content small">
                <div className='modal-header'>
                    <h5>{KeyWord.send_invoice}</h5>
                    <span className="close" onClick={()=>{dispatch(toggleInvoiceNotificationModal(null))}} >&times;</span>
                </div>
                <div className='modal-body'>
                    {
                        <Formik
                            enableReinitialize
                            initialValues={{
                                notification_method: 'email',
                                email: accountOwner?.contact_email
                                    ? accountOwner.contact_email
                                    : '',
                                phone_number: accountOwner?.contact_phone_number
                                    ? accountOwner.contact_phone_number
                                    : '',
                                invoice_reference_id: invoice_reference_id?.invoice_reference_id
                                    ? invoice_reference_id.invoice_reference_id
                                    : '',
                            }}
                            validationSchema={invoiceNotificationFormValidator}
                            autocomplete='off'

                            onSubmit={
                                (values) => {
                                    invoiceNotificationSubmitHandler(values)
                                        .catch((error) => {
                                            setError({
                                                code: 500,
                                                description: `Technical error: ${error.message}.`,
                                                translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
                                            })
                                        })
                                }
                            }
                        >
                            {({ values, status, errors, touched, isValid, submitCount, setFieldValue, handleSubmit, ...props }) => (
                                <Form>
                                    <div>
                                        <div className='form-fieldset '>
                                            <div role="group" aria-labelledby="notification-method-group" className='form-radio-button-group'>
                                                <div className='form-radio-button'>
                                                    <Field type="radio" id="notification_method_email" disabled={isLoading} name="notification_method" value="email" checked="checked"/>
                                                    <label htmlFor="notification_method_email">
                                                        {KeyWord.e_mail}
                                                    </label>
                                                </div>
                                                <div className='form-radio-button'>
                                                    <Field type="radio" id="notification_method_sms" disabled={isLoading} name="notification_method" value="sms" />
                                                    <label htmlFor="notification_method_sms">
                                                        SMS
                                                    </label>
                                                </div>
                                                <div className='form-radio-button'>
                                                    <Field type="radio" id="notification_method_all" disabled={isLoading} name="notification_method" value="all" />
                                                    <label htmlFor="notification_method_all">
                                                        {KeyWord.e_mail} & SMS
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='margin-t-l'>
                                            {
                                                values.notification_method === "email" ?
                                                    <TextInput
                                                        label={FormName.email.label}
                                                        disabled={isLoading}
                                                        label_small={FormName.email.label_small}
                                                        name={FormName.email.name}
                                                        type='email'
                                                        tool_tip={FormName.email.tool_tip}
                                                    />
                                                    : values.notification_method === "sms" ?
                                                        <TextInput
                                                            label={FormName.phone_number.label}
                                                            disabled={isLoading}
                                                            label_small={FormName.phone_number.label_small}
                                                            name={FormName.phone_number.name}
                                                            type='text'
                                                            tool_tip={FormName.phone_number.tool_tip}
                                                        />
                                                        :
                                                        <>
                                                            <div>
                                                                <TextInput
                                                                    label={FormName.email.label}
                                                                    disabled={isLoading}
                                                                    label_small={FormName.email.label_small}
                                                                    name={FormName.email.name}
                                                                    type='email'
                                                                    tool_tip={FormName.email.tool_tip}
                                                                />
                                                            </div>
                                                            <div className='margin-t-l'>
                                                                <TextInput
                                                                label={FormName.phone_number.label}
                                                                disabled={isLoading}
                                                                label_small={FormName.phone_number.label_small}
                                                                name={FormName.phone_number.name}
                                                                type='text'
                                                                tool_tip={FormName.phone_number.tool_tip}
                                                                />
                                                            </div>
                                                        </>
                                            }
                                        </div>
                                        {
                                            error &&
                                            <div className='base-alert --error margin-t-l'>
                                                {error.translation ? error.translation : null}
                                            </div>
                                        }
                                        {
                                            result &&
                                            <div className="base-alert --success margin-t-l">
                                                {KeyWord.send_invoice_success}
                                            </div>
                                        }
                                        <LoaderSmall isEnabled={isLoading} extraClass='margin-t-l'/>
                                        <div className='one-button-row margin-t-s'>
                                            <button className='btn primary medium send'
                                                    disabled={isLoading}
                                                    type='submit'
                                            >
                                                {KeyWord.send_invoice}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    }
                </div>
            </div>
        </div>
    );
};

export default InvoiceNotificationModalComponent;