import * as React from 'react';
import {KeyWord} from "../../constants";

export const PageNotFound = () => {

    return (
        <>
            <div className='page-not-found'>
                <div className='overlay'>
                    <div className='center-content'>
                        <div>
                            <h1>404</h1>
                        </div>
                        <div>
                            <h5>{KeyWord.page_not_found}</h5>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default PageNotFound;

