import * as React from 'react';
import { KeyWord } from '../../constants';
import {useDispatch, useSelector} from 'react-redux';
import { numberFormat } from "../../utils/helper-functions";
import { downloadLinkRequest,setDownloadError } from "../../features/customer/customerInvoicesSlice";
import {toggleInvoiceNotificationModal} from "../../features/customer/invoiceNotificationSlice";

export const InvoiceTableRow = ({ id, invoice_reference_id, amount, invoice_date, due_date, payment_date, is_paid}) => {
    const dispatch = useDispatch();
    const { customerData } = useSelector((store)=>store.customer);

    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
        var windowReference = window.open();
    }

    return (
        <>
            <div className={is_paid ? 'table__row' : 'table__row bg-red'}>
                <div className='table__cell'>{id}</div>
                <div className='table__cell'>{numberFormat(amount?.incl_vat, 2, ',', '')} kr
                </div>
                <div className='table__cell'>{invoice_date}</div>
                <div className='table__cell'>{due_date}</div>
                <div className='table__cell'>{payment_date}</div>
                <div className='table__cell'>
                    <button className='btn primary small download'
                        onClick={
                            ()=>{
                                downloadLinkRequest({'reference_id': customerData.reference_id, 'invoice_reference_id': invoice_reference_id})
                               .then(async (response) =>{
                                    let resp =  await response.json();

                                    if (resp.error) {
                                        dispatch(setDownloadError(resp.error))
                                    } else {
                                        if (navigator.userAgent.indexOf('Safari') !== -1
                                            && navigator.userAgent.indexOf('Chrome') === -1
                                            && typeof windowReference !== 'undefined'
                                        ) {
                                            windowReference.location = resp.data.download_link;
                                        } else {
                                            window.open(resp.data.download_link, '_blank');
                                        }
                                    }
                                }).catch((error) => {
                                    if (navigator.userAgent.indexOf('Safari') !== -1
                                        && navigator.userAgent.indexOf('Chrome') === -1
                                        && typeof windowReference !== 'undefined'
                                    ) {
                                        windowReference.close();
                                    }

                                    dispatch(setDownloadError({
                                        code: 500,
                                        description: `Technical error: ${error.message}.`,
                                        translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
                                    }))
                                })
                                }
                        }
                    >
                        {KeyWord.download}
                    </button>
                </div>
                <div className='table__cell'>
                    <button className='btn primary small send'
                            onClick={
                                ()=>{dispatch(toggleInvoiceNotificationModal({"invoice_reference_id": invoice_reference_id}))}
                            }
                    >
                        {KeyWord.send_invoice}
                    </button>
                </div>
            </div>
        </>
    );
};


export default InvoiceTableRow;
