import * as React from 'react';

const LoaderSmall = ({isEnabled = false, extraClass = ''}) => {
	return (
		<div className={extraClass}>
			{isEnabled ? (
				<div className='loader loader--overlay'>
					<div className='loader__center'>
						<div className='loader__icon --small' />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default LoaderSmall;
