import * as React from 'react';
import { KeyWord } from '../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { toggleProductModal } from '../../features/customer/changeProductSlice';
import { toggleCancelPricePlanChangeModal } from '../../features/customer/cancelPricePlanChangeSlice';


export const PricePlanTableRow = ({title, start_date, end_date, status}) => {
	const dispatch = useDispatch();
	const { pricePlan } = useSelector((store)=>store.pricePlan)

	return (
		<>
			<div className='table__row'>
				<div className='table__cell'>{title}</div>
				<div className='table__cell'>{start_date}</div>
				<div className='table__cell'>{end_date? end_date : 'N/A' }</div>
				<div className='table__cell'>{KeyWord[status] }</div>
				{
					pricePlan && pricePlan.length < 2 &&
					<div className='table__cell'>
						<button
							className='btn primary small edit-after'
							onClick={()=>{
								dispatch(toggleProductModal())
							}}
							>
							{KeyWord.change}
						</button>
					</div>
				}
				{
					(pricePlan && status==='scheduled') &&
					<div className='table__cell'>
						<button
							className='btn outlined small remove'
							onClick={()=>{
								dispatch(toggleCancelPricePlanChangeModal())
							}}
							>
							{KeyWord.cancel}
						</button>
					</div>
				}
			</div>
		</>
	);
};

export default PricePlanTableRow;