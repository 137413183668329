import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import { currentPricePlan } from '../../customerData';
import { Action, Auth } from "../../constants";
import { Buffer } from 'buffer';


export const getPricePlanRequest = createAsyncThunk('customer/getPricePlan', async (reference_id) => {
	let myHeaders = new Headers();
	let loggedUserData = localStorage.getItem("user");
	let loggedUser = JSON.parse(loggedUserData);
	const auth_token = loggedUser.auth_token;
	const encodedApiToken = Buffer.from(`${Auth.user}:${Auth.password}`).toString('base64');
	myHeaders.append('Authorization', 'Bearer ' + auth_token)
	myHeaders.append('Api-Token', encodedApiToken)
	let requestOptions = {
		method: 'GET',
		headers: myHeaders
	};
	return await fetch(Action.GET_PRICE_PLAN_URL+reference_id,requestOptions)
	.then(response => response.json());
})


const initialState = {
	pricePlan: null,
	originalResponse: {},
	binding: null,
	showModal: false,
	error: null,
	isLoading: false,
};

const customerPricePlanSlice = createSlice({
	name: 'pricePlan',
	initialState,
	reducers: {
		setPricePlanError: (state, action) => {
			state.error = action.payload
		},
		toggleCancelPricePlanChangeModal: (state) => {
			if (state.showModal) {
				state.showModal = false
			} else {
				state.showModal = true
			}
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getPricePlanRequest.pending, (state) => {
			state.pricePlan = null
			state.originalResponse = {}
			state.isLoading = true
			state.error = null
			state.binding = null
		})
		.addCase(getPricePlanRequest.fulfilled, (state, action) => {
			state.isLoading = false
			state.originalResponse = action.payload
			if (action.payload?.error?.code) {
				state.error = action.payload.error
			} else if (action.payload?.message?.success && action.payload?.data ) {
				state.pricePlan = action.payload.data
				state.binding = {
					'is_binding_period': action.payload.data[0].is_binding_period,
					'binding_period_end_date': action.payload.data[0].binding_period_end_date
				}
			}
		})
		.addCase(getPricePlanRequest.rejected, (state, action) => {
			state.pricePlan = null
			state.originalResponse = {}
			state.isLoading = false
			state.binding = null
			state.error = {
				code: 500,
				description: `Technical error: ${action.error.message}`,
				translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${action.error.message}`,
			}
		})
	}
});

export const { setPricePlanError, toggleCancelPricePlanChangeModal } = customerPricePlanSlice.actions;

export default customerPricePlanSlice.reducer;