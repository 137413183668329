import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoaderSmall from '../../loader/loader-small.component';
import { putCanclePricePlanChangeRequest, setError, toggleCancelPricePlanChangeModal } from '../../../features/customer/cancelPricePlanChangeSlice'
import { KeyWord } from '../../../constants';


export const CancelPricePlanChangeModalComponent = () => {
	const dispatch = useDispatch();
	const { showCancelPricePlanChangeModal, isLoading, error, disabled } = useSelector((store)=>store.cancelPricePlanChange)
	const { customerData } = useSelector((store)=>store.customer)

	const cancelPricePlangChangeRequestHandler = async (reference_id) => {
		return dispatch(putCanclePricePlanChangeRequest(reference_id))
		.catch((error)=> {
			setError({
				code: 500,
				description: `Technical error: ${error.message}.`,
				translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
			})
		});
	}

	return (
		<div id="myModal" className={showCancelPricePlanChangeModal? "modal active" : "modal"}>
			<div className="modal-content small">
				<div className='modal-header'>
					<h5>{KeyWord.cancel_product_change}</h5>
					<span className="close" onClick={()=>{dispatch(toggleCancelPricePlanChangeModal())}} >&times;</span>
				</div>
				<div className='modal-body text-center'>
					{
						!isLoading && !error &&
						<p className='confirmation-text margin-t-l'>{KeyWord.confirm_cancel_product_change}</p>
					}
					{
						isLoading &&
						<LoaderSmall isEnabled={isLoading} extraClass='margin-l-s'/>
					}
					{
						error &&
						<div className='base-alert --error margin-t-l'>
							{error?.translation ? error.translation : null}
						</div>
					}

					<div className='two-button-row margin-t-l'>
						<button
							disabled ={ disabled }
							className={'btn primary medium' + (disabled ? ' disabled' : '')}
							onClick={() => {
								cancelPricePlangChangeRequestHandler(customerData.reference_id);
							}}
						>
							{KeyWord.confirm_toggle}
						</button>
						<button className='btn outlined medium'
							onClick={()=>{dispatch(toggleCancelPricePlanChangeModal())}}
						>
							{KeyWord.cancel}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CancelPricePlanChangeModalComponent;