import React, { useEffect } from 'react';
import { GetCustomerDataPerson } from './customer-data-person.component';
import { GetCustomerTabBar } from './customer-tab-bar.component';
import { GetCustomerSim } from './customer-sim.component';
import { GetCustomerSubscription } from './customer-subscription.component';
import { CustomerProductOptions } from './customer-product-option.component';
import { CustomerBarringOptions } from './customer-barring-option.component';
import { OptionModalComponent } from './customer-modals/options-modal.component';
import { ProductModalComponent } from './customer-modals/product-modal.component';
import { CancelPricePlanChangeModalComponent } from './customer-modals/cancel-price-plan-change-modal.component'
import { CustomerSidebar } from './customer-sidebar.component';
import { useSelector, useDispatch } from 'react-redux';
import { NavMenuComponent } from "../navigation/nav-menu.component";
import InvoiceTable from "./customer-invoices.component";
import { getProductOptionsRequest, setProductError } from '../../features/customer/customerProductSlice';
import { getBarringOptionsRequest, setBarringError } from '../../features/customer/customerBarringSlice';
import { getCustomerSimRequest, setSimError } from '../../features/customer/customerSimSlice';
import { getSubscriptionsList, setSubscriptionsError, setAccountOwner } from '../../features/customer/customerSubscriptionsSlice';
import { getPossibleProductList, setProductListError } from '../../features/customer/getProductListSlice';
import { getPricePlanRequest, setPricePlanError } from '../../features/customer/customerPricePlanSlice';
import { setEndDate } from '../../features/customer/customerSlice';
import { invoiceListRequest, setInvoiceError } from "../../features/customer/customerInvoicesSlice";

import { GetCustomerUsage } from './customer-usage.component';



export const GetCustomerData = () => {
	const { customerData, error, isLoading } = useSelector((store)=>store.customer)
	const { customerSubscriptions, showInvoices,accountOwner } = useSelector((store)=>store.customerSubscriptionList)
	const { pricePlan } = useSelector((store)=>store.pricePlan)
	const { toggleSim } = useSelector((store)=>store.customerToggle)
	const dispatch = useDispatch();

	useEffect(() => {
		if (customerData) {
			dispatch(getPricePlanRequest(customerData.reference_id))
				.catch((error)=> {
					setPricePlanError({
						code: 500,
						description: `Technical error: ${error.message}.`,
						translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
					})
				});
			dispatch(getProductOptionsRequest(customerData.reference_id))
				.catch((error)=> {
					setProductError({
						code: 500,
						description: `Technical error: ${error.message}.`,
						translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
					})
				});
			dispatch(getBarringOptionsRequest(customerData.reference_id))
				.catch((error)=> {
					setBarringError({
						code: 500,
						description: `Technical error: ${error.message}.`,
						translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
					})
				});
			dispatch(getCustomerSimRequest(customerData.reference_id))
				.catch((error)=> {
					setSimError({
						code: 500,
						description: `Technical error: ${error.message}.`,
						translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
					})
				});
			dispatch(getPossibleProductList({"reference_id": customerData.reference_id}))
				.catch((error)=> {
					setProductListError({
						code: 500,
						description: `Technical error: ${error.message}.`,
						translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
					})
				});
		}

		if ((customerData && customerSubscriptions && !customerSubscriptions.find((item) => item.reference_id === customerData.reference_id)) ||
		(customerData && !customerSubscriptions)) {
			dispatch(getSubscriptionsList({"reference_id" : customerData.reference_id}))
				.catch((error)=> {
					setSubscriptionsError({
						code: 500,
						description: `Technical error: ${error.message}.`,
						translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
					})
				});
			dispatch(setAccountOwner(customerData.account_owner));
		}
	}, [customerData]);

	useEffect(() => {
		if(customerData && pricePlan){
			dispatch(setEndDate(pricePlan[0].end_date))
		}
	}, [pricePlan]);

	useEffect(() => {
		if(customerData){
			dispatch(invoiceListRequest(customerData.reference_id))
				.catch((error) => {
					setInvoiceError({
						code: 500,
						description: `Technical error: ${error.message}.`,
						translation: `Teknisk feil, prøv igjen om en liten stund eller kontakt kundesenteret: ${error.message}.`,
					})
				})
		}
	}, [accountOwner]);


	const { component } = useSelector((state) => state.customerToggle);


	return (
		<>
			<div>
				<NavMenuComponent />
				<div className='content-wrap content-wrap--wide margin-t-l padding-b-l'>
					<div className='content-wrap content-wrap customer-content'>
						<CustomerSidebar />
						<div className='main-content'>
							{
								error &&
								<div className='center-content'>
									<div className='base-alert --error margin-t-l'>
										{error.translation ? error.translation : null}
									</div>
								</div>
							}
							{
								isLoading &&
								<div className='center-content'>
									<div className='loader loader--overlay'>
										<div className='loader__center'>
											<div className='loader__icon' />
										</div>
									</div>
								</div>
							}
							{
								customerData && (
									<div className='checkout-content-box'>
										<div className='checkout-content-box__section'>

											{
												showInvoices ?
												<>
													<InvoiceTable />
												</>:
												<>
													<GetCustomerDataPerson />
													<GetCustomerTabBar />
															
													{component === 'sim' && <GetCustomerSim />}
													{component === 'subscription' && 
														<>
															<GetCustomerSubscription />
															<CustomerProductOptions />
															<CustomerBarringOptions />
															<OptionModalComponent />
															<ProductModalComponent />
															<CancelPricePlanChangeModalComponent />
														</>
													}
													{component === 'usage' && <GetCustomerUsage/>}
														
												</>
											}
										</div>
									</div>
								)
							}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default GetCustomerData;
